import React from 'react';
import { makeStyles } from "@mui/styles";
import { motion } from "framer-motion"

// MUI Imports
import {} from '@mui/material';

const useStyles = makeStyles({
  root: {},
  bkg: {
    fill: 'rgba(0, 0, 0, 0.02)'
  },
  dividerLine: {
    strokeWidth: '0.1',
    stroke: 'rgb(128, 128, 128, 0.8)',
    strokeDasharray: '0.4'
  },
  indicator: {
    fill: 'rgba(124, 124, 124, 0.5)'
  },
  text: {
    fontSize: '0.07em',
    letterSpacing: '0.04em'
  },
  triangle: {
    fill: 'rgb(121, 121, 121)'
  }
});


// if (score <= 13) {
//   depression = "Minimal"
// } else if (score > 13 && score < 20 ) {
//   depression = "Mild"
// } else if (score >= 21 && score < 29 ) {
//   depression = "Moderate"
// } else {
//   depression = "High"
// }
// max 63

const DepressionVis = (props) => {
  const { value } = props

  const classes = useStyles();

  const padding = 0.5 // padding on left and right of the main bar (so that edge numbers can still be centered)
  const lines = [ 13, 21, 30 ] // dotted divider lines
  const numbers = [ 0, 13, 21, 30, 63 ] // number labels

  return(
    <svg viewBox={`0 0 ${63 + padding * 2} 8`} xmlns="http://www.w3.org/2000/svg"
      className={classes.root}
    >
      <defs>
        <linearGradient id="bkgGrad">
          <stop offset="10%"  stopColor="rgb(249, 239, 211)" />
          <stop offset="100%" stopColor="rgb(57, 143, 186)" />
        </linearGradient>
      </defs>

      <rect width="63" x={padding} height="4" y="2" fill="url('#bkgGrad')" />

      {/* Divider Lines */}
      {lines.map((dist, index) => (
        <line key={index} x1={dist + padding} y1="2" x2={dist + padding} y2="6" className={classes.dividerLine} />
      ))}

      <motion.g
        animate={{x: value + padding}}
        initial={{x: 0}}
      >
        <circle cy="4" r="1.2" className={classes.indicator} />
        <circle cy="4" r="0.4" className={classes.indicator} />
      </motion.g>

      {/* Triangle */}
      {/* <motion.polygon
        points="0,7 1,6 2,7"
        className={classes.triangle}
        initial={{ translateX: '0px'}}
        animate={{ translateX: `${value-1}px`}}
      /> */}

      {/* Numbers */}
      {numbers.map((dist, index) => (
        <text key={index} x={dist + padding} y="7" textAnchor="middle" className={classes.text}>{dist}</text>
      ))}

      {/* Labels */}
      <text x="7.5" y="1.5" textAnchor="middle" className={classes.text}>Minimal</text>
      <text x="18" y="1.5" textAnchor="middle" className={classes.text}>Mild</text>
      <text x="26" y="1.5" textAnchor="middle" className={classes.text}>Moderate</text>
      <text x="47.5" y="1.5" textAnchor="middle" className={classes.text}>High</text>

    </svg>
  )
}

export default DepressionVis;
