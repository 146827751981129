import React, { useEffect, useState } from "react";
import SuccessModal from "../SuccessModal";
// redux imports
import { connect } from "react-redux";
import {
  addRecord,
  cleanAddRecordRedux,
  getRecordOptions,
  updateRecord,
} from "../../../../actions";

// MUI Imports
import { CircularProgress, Snackbar } from "@mui/material";
import { makeStyles } from '@mui/styles';
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import AddRecordSingleSelect from "../AddRecordSingleSelect";

// Styles
const useStyles = makeStyles((theme) => ({
  modalMain: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    backgroundColor: "#00000099",
    /* 80% opacity */
  },

  modalTrans: {
    position: "fixed",
    inset: 0,
    zIndex: -10,
  },

  modalcontainer: {
    fontFamily: "Roboto",
    minHeight: '30vh',
    // width: "90%",
    /* border-radius: 12px, */
    backgroundColor: "white",
    // padding: "16px",
    overflowX: "auto",
    overflowY: "visible", 
  },

  btnsContainer: {
    // width: "90%",
    // textAlign: "right",
    paddingTop: "20px",
  },

  subBtn: {
    fontFamily: "Roboto",
    padding: "10px 60px",
    fontWeight: 400,
    fontSize: "16px",
    borderRadius: "4px",
    border: "none",
    width: "fit-content",
    cursor: "pointer",

    backgroundColor: "#7D80C6",
    color: "white",
  },

  tableContainer: {
    border: "1px solid #6C757D",
    borderSpacing: 0,

    // '& > td, & > button': {
    "& > tbody > tr > td": {
      padding: 0,
    },
  },

  tableHeader: {
    fontFamily: "Bebas Neue",
  },

  tableHeaderItem: {
    fontSize: "16px",
    padding: "16px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    minWidth: "13%",
  },
  removeBtnClasses: {
    backgroundColor: "#fff",
    border: 0,
    padding: 0,
    cursor: "pointer",
  },
}));

const AddRecordModal = ({
  handleConfirmClose,
  record,
  updatedRecordId,
  setUpdatedRecordId,
  ...props
}) => {
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const { records, addUpdateRecordLoading, successMessage, filters } = props;

  const classes = useStyles();

  const initialRecord = {
    jobTitle: "",
    familyType: "",
    secondaryFamily: "",
    MBTI_1: "", //introvert/extrovert
    MBTI_2: "", //motivation
    MBTI_3: "", //judging/perceiving
    SII_1: "",
    SII_2: "",
    SII_3: "",
    salary: "",
    stress_level: "",
    min_edu: "",
    description: "",
  };

  const [recordData, setRecordData] = useState(initialRecord);

  const closeModalHandler = () => {
    handleConfirmClose();
    setRecordData(initialRecord);
    setUpdatedRecordId("");
  };

  const handleInput = (event) => {
    setErrorMessage("");
    const { name, value } = event.target;
    setRecordData((prevRecord) => ({
      ...prevRecord,
      // [name]: name === "description" ? value : value.trim(), // if description don't trim
      [name]: value,
    }));
  };

  const selectOnChangeHandler = (name, value) => {
    setErrorMessage("");
    const newRecord = {
      ...recordData,
      [name]: value,
    };
    

    setRecordData(newRecord);
  };

  const handleSuccessClose = () => {
    setOpenSuccessModal(false);
    handleConfirmClose();
    setRecordData(initialRecord);
    setUpdatedRecordId("");
  };

  const checkForEmptyRecord = (finalRecord) => {
    const noEmptyRecord = Object.keys(finalRecord).every(
      (key) => finalRecord[key] !== "" && finalRecord[key] != null
    );
    return noEmptyRecord;
  };

  const handleSubmit = () => {
    // Create a new object with all fields trimmed
    const trimmedRecord = Object.keys(recordData).reduce((acc, key) => {
      const value = recordData[key];

      // Use value?.trim() and fallback to the original value if not a string
      acc[key] = typeof value === "string" ? value?.trim() : value;
      return acc;
    }, {});

    setRecordData(trimmedRecord);

    if (!checkForEmptyRecord(trimmedRecord)) {
      setErrorMessage("All fields are required");
      return;
    }

    updatedRecordId === ""
      ? props.addRecord(trimmedRecord)
      : props.updateRecord(trimmedRecord);
  };

  useEffect(() => {
    if (updatedRecordId !== "" && records?.length > 0) {
      const SingleRecord = records?.find(
        (record) => record._id === updatedRecordId
      );
      if (SingleRecord) {
        setRecordData(SingleRecord);
      }
    }
  }, [updatedRecordId]);

  useEffect(() => {
    props.getRecordOptions();
  }, []);

  useEffect(() => {
 
    if (
      successMessage &&
      (successMessage === "record added successfully" ||
        successMessage === "Record updated successfully")
    ) {
      setOpenSuccessModal(true);
    } else if (
      successMessage &&
      successMessage === "Request failed with status code 404"
    ) {
      setErrorMessage("All fields should be filled");
    } else if (successMessage) {
      setErrorMessage("Record is not added");
    }
    return () => {
      props.cleanAddRecordRedux();
    };
  }, [successMessage]);


  return (
    <div className={classes.modalMain}>
      <div className={classes.modalTrans} onClick={closeModalHandler}></div>
      <div
        style={{
          backgroundColor: "white",
          width: "90%",
          padding: "16px",
          borderRadius: "20px",
        }}
      >
        <div style={{ textAlign: "end" }}>
          <button
            className={classes.removeBtnClasses}
            onClick={closeModalHandler}
          >
            <CloseIcon
              fontSize="inherit"
              htmlColor="#000000"
              style={{ fontSize: "28px" }}
            />
          </button>
        </div>
        <div className={`${classes.modalcontainer} no-scrollbar`}>
          <table className={classes.tableContainer}>
          <thead>
            <tr className={classes.tableHeader}>
              <th className={classes.tableHeaderItem}>Job Title</th>
              <th className={classes.tableHeaderItem}>Family/Type</th>
              <th className={classes.tableHeaderItem}>Secondary Family</th>
              <th className={classes.tableHeaderItem}>Interest 1</th>
              <th className={classes.tableHeaderItem}>Interest 2</th>
              <th className={classes.tableHeaderItem}>Interest 3</th>
              <th className={classes.tableHeaderItem}>Introvert/Extrovert</th>
              <th className={classes.tableHeaderItem}>Motivation</th>
              <th className={classes.tableHeaderItem}>Judging/Percieving</th>
              <th className={classes.tableHeaderItem}>Salary</th>
              <th className={classes.tableHeaderItem}>Stress Level</th>
              <th className={classes.tableHeaderItem}>Minimum Education</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <AddRecordSingleSelect
                  name="jobTitle"
                  selectValue={recordData.jobTitle}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.jobTitle}
                  searchOptions={true}
                />
              </td>
              <td>
                <AddRecordSingleSelect
                  name="familyType"
                  selectValue={recordData.familyType}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.familyType}
                />
              </td>
              <td>
                <AddRecordSingleSelect
                  name="secondaryFamily"
                  selectValue={recordData.secondaryFamily}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.secondaryFamily}
                />
              </td>
              <td>
                <AddRecordSingleSelect
                  name="SII_1"
                  selectValue={recordData.SII_1}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.SII_1}
                />
              </td>

              <td>
                <AddRecordSingleSelect
                  name="SII_2"
                  selectValue={recordData.SII_2}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.SII_2}
                />
              </td>
              <td>
                <AddRecordSingleSelect
                  name="SII_3"
                  selectValue={recordData.SII_3}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.SII_3}
                />
              </td>
              <td>
                {/* MBTI code fields */}
                <AddRecordSingleSelect
                  name="MBTI_1"
                  selectValue={recordData.MBTI_1}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.MBTI_1}
                />
              </td>
              <td>
                <AddRecordSingleSelect
                  name="MBTI_2"
                  selectValue={recordData.MBTI_2}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.MBTI_2}
                />
              </td>
              <td>
                <AddRecordSingleSelect
                  name="MBTI_3"
                  selectValue={recordData.MBTI_3}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.MBTI_3}
                />
              </td>
              <td>
                <AddRecordSingleSelect
                  name="salary"
                  selectValue={recordData.salary}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.salary}
                />
              </td>
              <td>
                <AddRecordSingleSelect
                  name="stress_level"
                  selectValue={recordData.stress_level}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.stress_level}
                />
              </td>
              <td>
                <AddRecordSingleSelect
                  name="min_edu"
                  selectValue={recordData.min_edu}
                  selectOnChangeHandler={selectOnChangeHandler}
                  options={filters?.min_edu}
                />
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div
          className={classes.tableHeaderItem}
          style={{ padding: "20px 0 6px 0",marginTop:'-15vh' }}
        >
          Description
        </div>
        <textarea
          className={classes.inputfield}
          style={{
            width: "100%",
            fontFamily: "Lato",
            borderRadius: "4px",
            borderColor: "#383050",
            padding: "4px",
            resize: "none",
            outlineColor:'#383050'
          }}
          name="description"
          placeholder="Enter"
          rows={12}
          value={recordData.description}
          onChange={handleInput}
        />
        {/* {successMessage && <p>{successMessage}</p>} */}
        {errorMessage && (
          <div
            style={{
              color: "red",
              fontFamily: "Roboto",
              fontSize: "14px",
              paddingTop: "8px",
            }}
          >
            {errorMessage}
          </div>
        )}
        <div className={classes.btnsContainer}>
          <button className={classes.subBtn} onClick={handleSubmit}>
            {addUpdateRecordLoading ? (
              <CircularProgress style={{ color: "white" }} size={15} />
            ) : updatedRecordId === "" ? (
              "Submit"
            ) : (
              "Update"
            )}
          </button>
        </div>
      </div>
      {openSuccessModal && (
        <SuccessModal
          // openSuccessModal={openSuccessModal}
          handleSuccessClose={handleSuccessClose}
        />
      )}

      {/* {errorMessage && (
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={errorMessage !== ""}
          autoHideDuration={5000}
          onClose={() => setErrorMessage("")}
        >
          <Alert elevation={6} variant="filled" severity={"error"}>
            {errorMessage}
          </Alert>
        </Snackbar>
      )} */}
    </div>
  );
};

const mapDispatchToProps = {
  addRecord,
  updateRecord,
  cleanAddRecordRedux,
  getRecordOptions,
};

const mapStateToProps = (state) => {
  return {
    records: state.records.records,
    successMessage: state.records.successMessage,
    addUpdateRecordLoading: state.records.addUpdateRecordLoading,
    filters: state.records.filters,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddRecordModal);
