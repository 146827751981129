import { produce } from 'immer';
import { setAutoFreeze } from 'immer';

import {
  UPDATE_ANSWER, UPDATE_ALL_ANSWERS, INIT_CARD_SORT, REORDER_CARD, MOVE_CARD,
  SET_ANSWER_LOADING_STATUS, SET_USER_DATA
} from "../actions/index";

setAutoFreeze(false);

const initialState = {
  byId: {},
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {

      case UPDATE_ANSWER: {
        const { assessmentId, question, answer, questionType } = action
        // console.log(questionType)

        // create answers data structure for new assessment if it doesnt already exist
        if (!draft.byId[assessmentId]) {
          draft.byId[assessmentId] = {
            completed: false,
            answers: []
          }
        }
        if (questionType === 'checkbox') {
          const prevAnswer = draft.byId[assessmentId].answers[question - 1]

          if (!prevAnswer) {
            console.log('no answer found - adding blank answer')
            draft.byId[assessmentId].answers[question - 1] = {}
          }
          if (answer.value || answer.value === "") {
            console.log('time to add new entry')
            console.log('choice:', answer.choice)
            console.log('value:', answer.value)
            draft.byId[assessmentId].answers[question - 1][answer.choice] = answer.value
          } else {
            // remove false or null values
            delete draft.byId[assessmentId].answers[question - 1][answer.choice]
          }

        } else if (questionType === 'multiText') {
          const { index, value } = answer
          const answerSet = draft.byId[assessmentId].answers[question - 1]
          if (!answerSet) {
            // initialize answer set
            draft.byId[assessmentId].answers[question - 1] = []
          }
          draft.byId[assessmentId].answers[question - 1][index] = value
        } else {
          draft.byId[assessmentId].answers[question - 1] = answer
        }

        return draft
      }

      case SET_USER_DATA: {
        const assessmentData = action.data.assessmentData
        const cardSortAnswers = assessmentData.find(assessment => assessment._id === 'career-values')
        if (cardSortAnswers) {
          if (draft.byId[cardSortAnswers._id]) {
            draft.byId[cardSortAnswers._id].answers = cardSortAnswers.answers
          } else {
            draft.byId[cardSortAnswers._id] = {
              completed: cardSortAnswers.completed,
              loading: 'done',
              answers: cardSortAnswers.answers
            }
          }
        }
        return draft
      }

      case UPDATE_ALL_ANSWERS: {
        const assessmentId = action.answerData.assessmentId
        let answers = action.answerData.answers

        if (assessmentId === 'consent' || assessmentId === 'depression-screening' ||
          assessmentId === 'neo') {
          // convert answers to strings
          answers = answers.map(answer => {
            if (answer != null) {
              return answer.toString()
            } else {
              return null
            }
          })
        }

        draft.byId[assessmentId].answers = answers
        // console.log(draft.byId[assessmentId].answers)

        return draft
      }

      case INIT_CARD_SORT: {
        const { assessmentId, questions } = action
        const startCards = questions.cards

        // use question data structure as starting point for answer data
        const answers = {
          ...questions.valueBins.byId
        }

        // create answers data structure for new assessment if it doesnt already exist
        if (!draft.byId[assessmentId]) {
          draft.byId[assessmentId] = {
            completed: false,
            answers: {}
          }
        }
        draft.byId[assessmentId].answers = answers

        // put all cards in 'start' bin
        draft.byId[assessmentId].answers.start.cards = startCards

        return draft
      }

      case REORDER_CARD: {
        const { listData, assessmentId, listId } = action
        draft.byId[assessmentId].answers[listId].cards = listData
        return draft
      }

      case MOVE_CARD: {
        const { cardData, assessmentId, sourceId, destId } = action
        draft.byId[assessmentId].answers[sourceId].cards = cardData[sourceId]
        draft.byId[assessmentId].answers[destId].cards = cardData[destId]
        return draft
      }

      case SET_ANSWER_LOADING_STATUS: {
        // loading: true, false, 'done'
        const { assessmentId, status } = action
        if (draft.byId[assessmentId]) {
          draft.byId[assessmentId].loading = status
        } else {
          draft.byId[assessmentId] = {
            completed: false,
            loading: status,
            answers: []
          }
        }

        return draft
      }

    default:
      break;
    }
  });
