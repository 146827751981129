import { produce } from 'immer';

import {
  SET_USER_DATA,
  UPDATE_PROGRESS,
  SET_USER_CONSENT,
  SET_COMPLETED
} from "../actions/index";

const initialState = {};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {

      case SET_USER_DATA:
        if (action.data.plans) {
          let assessmentMetadata = action.data.plans[0].assessments
          let byId = {}
          let allIds = []

          // iterate over all assessments to build byId and allIds objects
          assessmentMetadata.forEach(assessment => {
            byId[assessment._id] = {
              ...assessment,
              completed: false,      // default value
              questionsAnswered: 0   // default value
            }
            allIds.push(assessment._id)
          })

          // update assessmentMetadata with any user progress from assessmentMetadata
          let userAssessmentData = action.data.assessmentData
          if (userAssessmentData) {

            // remove interview from answers when plan is premium
            if (action.data.plans[0]._id === 'premium') {
              userAssessmentData = userAssessmentData.filter(assessment => assessment._id !== 'interview')
            }
            userAssessmentData.forEach(assessment => {
              // console.log('assessment', assessment)
              const { completed, questionsAnswered, _id } = assessment
              // console.log('id', _id)
              // console.log('byId[_id]', byId[_id])
              byId[_id].completed = completed
              byId[_id].questionsAnswered = questionsAnswered
            })
          }

          draft.byId = byId
          draft.allIds = allIds

          return draft
        } else {
          return draft
        }

      case SET_USER_CONSENT:
        draft.byId['consent'].completed = true
        return draft

      case SET_COMPLETED: {
        const { assessmentId } = action
        console.log(`setting ${assessmentId} as completed!`)
        draft.byId[assessmentId].completed = true

        return draft
      }

      case UPDATE_PROGRESS: {
        const { userId, assessmentId, questionsAnswered, checkComplete } = action

        if (checkComplete) {
          draft.byId[assessmentId].completed =
          questionsAnswered === state.byId[assessmentId].totalQuestions ? true : false
        }
        draft.byId[assessmentId].questionsAnswered = questionsAnswered

        return draft
      }

      default:
        break;
    }
  })
