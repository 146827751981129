import React from "react";
// import "./styles.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Link } from "react-router-dom";
// MUI Imports
import { makeStyles } from "@mui/styles";

import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";

// Styles
const useStyles = makeStyles((theme) => ({
  modalMain: {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%",
    backgroundColor: "#00000099",
    /* 80% opacity */
  },

  modalTrans: {
    position: "fixed",
    inset: 0,
    zIndex: -10,
  },

  headerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },

  heading: {
    fontSize: "46px",
    fontWeight: "700",
    color: "#7D80C6",
  },
  labelText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "black",
    fontFamily: "Roboto",
    paddingTop: "34px",
    paddingBottom: "4px",
    textAlign: "center",
    width: "80%",
  },

  iconContainer: {
    backgroundColor: "#7D80C6",
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
    width: "400px",
    textAlign: "center",
  },

  icon: {
    color: "white",
    width: "100px !important",
    height: "100px !important",
    marginTop: "70px",
    marginBottom: "70px",
  },
  doneBtn: {
    paddingLeft: "60px",
    paddingRight: "60px",
    paddingTop: "10px",
    paddingBottom: "10px",
    fontWeight: " 400",
    fontSize: "16px",
    fontFamily: "Roboto",
    textTransform: "capitalize",
    backgroundColor: "#7D80C6",
    color: "white",
    borderRadius: "4px",
    border: "none",
    marginBottom: "28px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#6B6EB5",
    },
  },
  dialogPaper: {
    borderRadius: "10px",
    backgroundColor: "white",
  },
}));

export default function SuccessModal({ handleSuccessClose }) {
  const classes = useStyles();

  return (
    <div className={classes.modalMain}>
      <div className={classes.modalTrans} onClick={handleSuccessClose}></div>

      <div className={classes.dialogPaper}>
        <div className={classes.iconContainer}>
          <CheckCircleIcon className={classes.icon} />
        </div>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography className={classes.labelText}>
            The record has been submitted successfully.
          </Typography>
          <br />
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button onClick={handleSuccessClose} className={classes.doneBtn}>
            Done
          </Button>
        </DialogActions>
      </div>
    </div>
  );
}

// return (
//   <Dialog
//     open={openSuccessModal}
//     // onClose={handleSuccessClose}
//     classes={{ paper: classes.dialogPaper }}

//   >
//     {/* <DialogTitle disableTypography className={classes.mobileWarningTitle}> */}
//     <div className={classes.iconContainer}>
//       <CheckCircleIcon className={classes.icon} />
//     </div>
//     <DialogContent
//       style={{
//         display: "flex",
//         justifyContent: "center",
//       }}
//     >
//       <Typography className={classes.labelText}>
//         The record has been submitted successfully.
//       </Typography>
//       <br />
//     </DialogContent>
//     <DialogActions style={{ justifyContent: "center" }}>
//       {/* <Link to="/admin/search-record"> */}
//         <Button onClick={handleSuccessClose} className={classes.doneBtn}>
//           Done
//         </Button>
//       {/* </Link> */}
//     </DialogActions>
//   </Dialog>
// );
