import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import axios from 'axios';

import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';

// MUI Imports
import {
	Typography,
	Button,
	CircularProgress,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	MenuItem,
	FormControl,
	Select,
	ButtonGroup,
	TextField,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	DialogContentText,
} from '@mui/material';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonIcon from '@mui/icons-material/Person';
import LoopIcon from '@mui/icons-material/Loop';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';

// Actions
import {
	fetchUserData,
	fetchPlans,
	requestSetUserPlan,
} from '../../actions/index';

const assessmentNames = {
	consent: 'Consent',
	'depression-screening': 'Depression Screening',
	interview: 'Interview',
	neo: 'NEO',
	'myers-briggs': 'Elevate (MBTI/SCI)',
	'career-values': 'Career Values Card Sort',
};

const useStyles = makeStyles({
	root: {
		minWidth: '900px',
	},
	button: {
		margin: '10px 5px',
	},
	progressDetail: {
		marginBottom: '10px',
	},
	resultsButtonsContainer: {
		display: 'flex',
		margin: '0 -5px',
		marginBottom: '10px',
		'& a': {
			flex: '1 1 50%',
		},
	},
	advancedContainer: {
		display: 'flex',
		flexDirection: 'column',
		padding: '16px',
		paddingTop: 0,
	},
	advancedButtonsContainer: {
		display: 'flex',
		margin: '0 -5px',
		marginBottom: '10px',
		'& > div, & > button': {
			flex: '1 1 50%',
		},
	},
	elevate: {
		display: 'flex',
		flexDirection: 'row',
		marginTop: '10px',
		justifyContent: 'space-between',
		'& div': {
			flex: '1 1 33%',
		},
	},
	reFetchButton: {
		marginLeft: '10px',
		height: '100%',
	},
	dropdown: {
		minWidth: '200px',
	},
	advancedBar: {
		'& h2': {
			display: 'flex',
			justifyContent: 'space-between',
		},
	},
	accordion: {
		backgroundColor: 'rgb(255, 235, 235)',
	},
	columns: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		'& p': {
			paddingLeft: '20px',
			marginBottom: 0,
		},
	},
});

const DetailView = (props) => {
	const { user } = props;

	const [mbSyncMode, setMbSyncMode] = useState('testDate');
	const [daysBack, setDaysBack] = useState(5);
	const [syncEmail, setSyncEmail] = useState(false);
	const [syncStatus, setSyncStatus] = useState(false); // false, 'syncing', 'done'
	const [syncResult, setSyncResult] = useState(false); // sync results
	const [syncDialogOpen, setSyncDialogOpen] = useState(false);
	const [dateRange, setDateRange] = useState({
		startDate: null,
		endDate: null,
	});

	const classes = useStyles();

	const checkForMbResults = (user, type) => {
		if (user.leanAssessmentData) {
			const data = user.leanAssessmentData.find(
				(assessment) => assessment.id === 'myers-briggs'
			);
			if (data && data[type] === true) {
				return true;
			} else return false;
		} else return false;
	};

	const userNeedsElevateSync = (user) => {
		if (user.plans[0].assessments.includes('myers-briggs')) {
			if (user.leanAssessmentData) {
				const data = user.leanAssessmentData.find(
					(assessment) => assessment.id === 'myers-briggs'
				);
				if (data && data.completed) {
					return true;
				} else {
					return false;
				}
			}
		} else {
			return false;
		}
	};

	const fetchPlans = () => {
		if (!props.loading && props.plans.length === 0) {
			console.log('loading plans...');
			props.fetchPlans();
		}
	};

	const handleLoginAsUser = (userId) => {
		props.fetchUserData(userId);
	};

	const handleEmailChange = (event) => {
		const text = event.target.value;
		setSyncEmail(text);
	};

	const handlePlanChange = (event) => {
		const newPlanId = event.target.value;
		const planData = {
			userId: user._id,
			planId: newPlanId,
		};
		props.requestSetUserPlan(planData);
	};

	const attemptElevateDataSync = async (requestPayload) => {
		console.log('Attempting to sync Elevate data...');
		setSyncStatus('syncing');
		setSyncDialogOpen(true);

		const response = await axios
			.post('/api/external/elevate', requestPayload)
			.catch((err) => console.log(err));

		console.log('response:', response.data);

		setSyncResult({ ...response.data });
		setSyncStatus('done');
		setSyncDialogOpen(true);
	};

	const renderStatusIcon = (status) => {
		if (status) {
			switch (status) {
				case 'failed':
				case 'no user found':
				case 'no assessments found':
				case 'max attempts exceeded':
					return '❌';
				case 'new data saved':
				case 'data already exists':
					return '✅';
				default:
					return status;
			}
		} else {
			return 'no status';
		}
	};

	const handleReFetch = (email) => {
		const userData = user.leanAssessmentData.find(
			(assessment) => assessment.id === 'myers-briggs'
		);
		const gender = userData.gender;
		// '04-05-2020', '04-15-2020', // ck dates
		if (gender === 1) {
			console.log(
				"User's gender not properly set. Most likely this is legacy data from before SII OS scoring was fixed"
			);
		} else {
			const testDate = moment.utc(
				user.leanAssessmentData.find(
					(assessment) => assessment.id === 'myers-briggs'
				)?.completedAt
			);

			const startDate =
				mbSyncMode === 'dateRange'
					? dateRange.startDate.format('MM-DD-YYYY')
					: mbSyncMode === 'testDate'
						? testDate.subtract(2, 'days').format('MM-DD-YYYY')
						: false;

			const endDate =
				mbSyncMode === 'dateRange'
					? dateRange.endDate.format('MM-DD-YYYY')
					: mbSyncMode === 'testDate'
						? testDate.add(4, 'days').format('MM-DD-YYYY')
						: false;

			attemptElevateDataSync({
				userId: user._id,
				email: syncEmail || email,
				skipGenerate: false,
				daysBack: mbSyncMode === 'daysBack' ? daysBack : false,
				startDate: startDate,
				endDate: endDate,
				gender: gender,
			});
		}
	};

	return (
		<Dialog
			open={props.open}
			maxWidth='sm'
			fullWidth={true}
			className={classes.root}
			onClose={() => {
				setSyncEmail(false);
				props.handleCloseDialog();
			}}
		>
			<DialogTitle className={classes.advancedBar}>
				{`${user.firstName} ${user.lastName}`}
				<div>
					{user.plans &&
						user.plans.map((plan, index) => (
							<Chip
								key={index}
								label={plan.name}
								size='small'
								color={
									plan._id === 'free'
										? 'default'
										: plan._id === 'premium'
											? 'secondary'
											: 'primary'
								}
							/>
						))}
				</div>
			</DialogTitle>
			<DialogContent>
				<Typography variant='h6' gutterBottom>
					Progress
				</Typography>

				<div className={classes.progressDetail}>
					<TableContainer>
						<Table size='small'>
							<TableBody>
								{user.plans[0].assessments.map((assessmentId) => {
									const status = user.leanAssessmentData.find(
										(assessment) => assessment.id === assessmentId
									);
									return (
										<TableRow key={assessmentId} hover={true}>
											<TableCell component='th' scope='row'>
												<Typography>{assessmentNames[assessmentId]}</Typography>
											</TableCell>
											<TableCell align='right'>
												<Typography>
													{status && status.completed
														? '✅'
														: status
															? '✏️'
															: ''}
												</Typography>
											</TableCell>
											<TableCell align='right'>
												{status && status.completed ? (
													<Typography color='textSecondary'>
														{moment
															.utc(status.completedAt)
															.format('MMMM Do, YYYY')}
													</Typography>
												) : (
													''
												)}
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</div>

				<div className={classes.resultsButtonsContainer}>
					<Button
						variant='contained'
						color='primary'
						component={Link}
						to={`/results/${user._id}`}
						className={classes.button}
					>
						Results
					</Button>

					{user.leanAssessmentData.find(
						(assessment) => assessment.id === 'interview'
					)?.completed && (
							<Button
								variant='contained'
								color='primary'
								component={Link}
								to={`/interview/${user._id}`}
								className={classes.button}
							>
								Interview
							</Button>
						)}
				</div>

				<Accordion className={classes.accordion}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />}>
						<Typography>
							<b>Advanced Controls</b>
						</Typography>
					</AccordionSummary>

					<AccordionDetails className={classes.advancedContainer}>
						<>
							<div className={classes.advancedButtonsContainer}>
								<Button
									onClick={() => handleLoginAsUser(user._id)}
									className={classes.button}
									startIcon={<PersonIcon />}
									variant='outlined'
								>
									login As {user.firstName}
								</Button>

								{/* Change Plan */}

								{/* Load Plans Button */}
								{props.plans.length === 0 && (
									<Button
										variant='outlined'
										fullWidth
										onClick={fetchPlans}
										className={classes.button}
										startIcon={<SwapHorizontalCircleIcon />}
									>
										Change Plan
									</Button>
								)}

								{/* Select Plan Dropdown */}
								{props.plans && props.plans.length !== 0 && (
									<FormControl className={classes.dropdown}>
										<Select
											variant='outlined'
											value={user.plans[0]._id}
											onChange={handlePlanChange}
										>
											{props.plans.map((plan, index) => {
												return (
													<MenuItem key={index} value={plan._id}>
														{plan.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								)}

								{props.loading && <CircularProgress />}
							</div>

							{/* FETCH ELEVATE DATA */}
							{userNeedsElevateSync(user) && (
								<div className={classes.elevate}>
									<div>
										<Typography variant='h6' gutterBottom>
											Elevate Sync:
										</Typography>
										<Typography display='inline'>
											<b>
												MBTI: {checkForMbResults(user, 'MBTI') ? '✅' : '❌'}
											</b>
										</Typography>
										&nbsp;
										<Typography display='inline'>
											<b>SII: {checkForMbResults(user, 'SII') ? '✅' : '❌'}</b>
										</Typography>
										{syncEmail ? (
											<TextField
												label='Email'
												variant='outlined'
												size='small'
												value={syncEmail}
												onChange={handleEmailChange}
												style={{
													marginTop: '10px',
													marginRight: '10px',
												}}
											/>
										) : (
											<Button
												variant='outlined'
												size='small'
												style={{ marginTop: '10px' }}
												onClick={() => setSyncEmail(user.email)}
											>
												Override Email
											</Button>
										)}
									</div>

									<div>
										<ButtonGroup color='primary' size='small'>
											<Button
												variant={mbSyncMode === 'testDate' ? 'contained' : ''}
												onClick={() => setMbSyncMode('testDate')}
											>
												Test Date
											</Button>
											<Button
												variant={mbSyncMode === 'daysBack' ? 'contained' : ''}
												onClick={() => setMbSyncMode('daysBack')}
											>
												Days Back
											</Button>
											<Button
												variant={mbSyncMode === 'dateRange' ? 'contained' : ''}
												onClick={() => setMbSyncMode('dateRange')}
											>
												Date Range
											</Button>
										</ButtonGroup>

										{mbSyncMode === 'testDate' && (
											<Typography align='center' style={{ padding: '10px' }}>
												{moment
													.utc(
														user.leanAssessmentData.find(
															(assessment) => assessment.id === 'myers-briggs'
														)?.completedAt
													)
													.format('MMMM Do, YYYY')}
											</Typography>
										)}

										{mbSyncMode === 'daysBack' && (
											<TextField
												value={daysBack}
												style={{ marginTop: '20px' }}
												onChange={(e) => setDaysBack(e.target.value)}
												id='outlined-basic'
												label='Days Back'
												variant='outlined'
												size='small'
												type='number'
											/>
										)}

										{mbSyncMode === 'dateRange' && (
											<LocalizationProvider dateAdapter={MomentUtils}>
												<DatePicker
													label="Start Date"
													inputFormat="MM-DD-YYYY"
													value={dateRange.startDate}
													onChange={(date) =>
														setDateRange({
															startDate: date,
															endDate: dateRange.endDate,
														})
													}
												/>
												<DatePicker
													label="End Date"
													inputFormat="MM-DD-YYYY"
													value={dateRange.endDate}
													onChange={(date) =>
														setDateRange({
															startDate: dateRange.startDate,
															endDate: date,
														})
													}
												/>
											</LocalizationProvider>
										)}
									</div>

									<div>
										<Button
											onClick={() => handleReFetch(user.email)}
											className={classes.reFetchButton}
											startIcon={<LoopIcon />}
											color='primary'
											variant='outlined' //'contained'
										>
											Sync Elevate Data
										</Button>
									</div>

									{/* Sync Report */}
									<Dialog
										open={syncDialogOpen}
										onClose={() => setSyncDialogOpen(false)}
									>
										<DialogTitle>
											Elevate Sync Report for {user.firstName} {user.lastName}
										</DialogTitle>
										<DialogContent>
											{syncStatus === 'syncing' && (
												<div className={classes.columns}>
													<CircularProgress size={30} />
													<DialogContentText>
														Syncing... Be patient! Elevate sync can take up to
														two minutes.
													</DialogContentText>
												</div>
											)}
											{syncStatus === 'done' && (
												<div className={classes.columns}>
													<div>
														<Typography>
															<b>MBTI</b>
														</Typography>
														<p>
															{renderStatusIcon(syncResult.mbti.status)}{' '}
															{syncResult.mbti.detail}
														</p>
													</div>
													<div>
														<Typography>
															<b>SII</b>
														</Typography>
														<p>
															{renderStatusIcon(syncResult.sii.status)}{' '}
															{syncResult.sii.detail}
														</p>
													</div>
												</div>
											)}
										</DialogContent>
										<DialogActions>
											<Button
												onClick={() => setSyncDialogOpen(false)}
												color='primary'
												autoFocus
											>
												Done
											</Button>
										</DialogActions>
									</Dialog>
								</div>
							)}
						</>
					</AccordionDetails>
				</Accordion>
			</DialogContent>

			<DialogActions>
				<Button onClick={props.handleCloseDialog} color='primary'>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const mapDispatchToProps = {
	fetchUserData,
	fetchPlans,
	requestSetUserPlan,
};

const mapStateToProps = (state) => {
	return {
		loading: state.plans.loading,
		plans: state.plans.allPlans,
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DetailView);
