import React from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import isEmpty from "is-empty";

// MUI Imports
import { Snackbar } from "@mui/material";
import Alert from "@mui/material/Alert";

// actions
import { clearFlash } from "../../actions";

const useStyles = makeStyles({
  root: {},
});

const Flash = (props) => {
  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    // clear redux message
    props.clearFlash();
  };

  return (
    <Snackbar
      className={classes.root}
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      open={!isEmpty(props.flash)}
      autoHideDuration={5000}
      onClose={handleClose}
    >
      {/* error, warning, info, success */}
      <Alert elevation={6} variant="filled" severity={props.flash.type}>
        {props.flash.text ===
        "Failed to save answers - error: Error: Request failed with status code 444"
          ? "Please complete both MBTI and Strong Interest Inventory (SII) test before submitting answer"
          : props.flash.text}
      </Alert>
    </Snackbar>
  );
};

const mapDispatchToProps = {
  clearFlash,
};

const mapStateToProps = (state) => {
  return {
    flash: state.flash,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Flash);
