import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// My Components
import Centered from "../layout/Centered";

// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIcon from "@mui/icons-material/ArrowForwardIos";
import DoneIcon from "@mui/icons-material/Done";
import SaveIcon from "@mui/icons-material/Save";

// My Components
import SelectOne from "./interviewQuestionTypes/SelectOne";
import SelectMultiple from "./interviewQuestionTypes/SelectMultiple";
import Text from "./interviewQuestionTypes/Text";
import MultiText from "./interviewQuestionTypes/MultiText";
import AssessmentProgressBar from "../widgits/AssessmentProgressBar";
// MUI Imports
import {
  Typography,
  Button,
  CircularProgress,
  Container,
  Paper,
} from "@mui/material";

// Actions
import { submitAnswers, fetchAnswers, sendFlash } from "../../actions/index";

const useStyles = makeStyles({
  root: {},
  paper: {
    padding: "40px",
    marginTop: "30px",
  },
  footer: {
    padding: "30px 0",
  },
  buttonBox: {
    display: "flex",
    justifyContent: "center",
  },
  button: {
    margin: "10px",
  },
  sharedQuestionStyles: {
    // root
    display: "block",
    padding: "40px",
    marginTop: "30px",

    // question text
    "& > div.MuiFormControl-root > label": {
      fontSize: "2em",
      lineHeight: "1.3em",
      marginBottom: "20px",
    },
  },
});

const Interview = (props) => {
  // const [currentSection, setCurrentSection] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);
  const classes = useStyles();

  const { assessment, user } = props;

  console.log(assessment);
  const sections =
    assessment.questionVersions &&
    assessment.questionVersions[0].questions.sections;
  const questions =
    assessment.questionVersions &&
    assessment.questionVersions[0].questions.questions;

  useEffect(() => {
    if (!props.answersLoading) {
      // on load fetch completed answers
      let answerData = {
        userId: user._id,
        assessmentId: assessment._id,
      };
      props.fetchAnswers(answerData);
    }
  }, []);

  const handleSubmitAnswers = (markComplete) => {
    let answerData = {
      userId: user._id,
      assessmentId: assessment._id,
      totalQuestions: assessment.questionVersions[0].totalQuestions,
      markComplete: markComplete,
    };
    props.submitAnswers(answerData, markComplete);
  };

  const handleChangeSection = (step) => {
    setCurrentSection(currentSection + step);
    window.scrollTo(0, 0);
  };

  const Question = (props) => {
    const type = props.item.type;
    const showIf = props.item.showIf;
    let hidden = false;

    // default behavior - hide question

    if (!hidden) {
      switch (type) {
        case "radio":
          return <SelectOne {...props} />;

        case "checkbox":
          return <SelectMultiple {...props} />;

        case "text":
          return <Text {...props} />;

        case "multiText":
          return <MultiText {...props} />;

        default:
          return (
            <div
              key={props.index}
            >{`${props.number}. ${props.item.question}`}</div>
          );
      }
    } else {
      return <div></div>;
    }
  };

  const section = sections[currentSection];

  return (
    <Container maxWidth="md">
      {props.answersLoading !== "done" && (
        <Centered>
          <CircularProgress />
        </Centered>
      )}

      {/* Top */}
      <Paper className={classes.paper}>
        <Typography variant="h4" gutterBottom>
          {section.title}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {section.subtitle}
        </Typography>
      </Paper>

      {/* Questions */}
      <div>
        {
          // Filter to subset of questions in current section
          Object.keys(questions)
            .filter((questionNum) => {
              if (questionNum >= section.from && questionNum <= section.to) {
                return true;
              } else return false;
            })
            .map((questionNum) => {
              const question = questions[questionNum];
              return (
                <Question
                  key={questionNum}
                  number={questionNum}
                  item={question}
                  userId={user._id}
                  assessmentId={assessment._id}
                  questionStyles={classes.sharedQuestionStyles}
                />
              );
            })
        }
      </div>

      {/* FOOTER */}
      <div className={classes.footer}>
        {/* BUTTONS */}
        <div className={classes.buttonBox}>
          {/* PREV SECTION */}
          {currentSection > 0 ? (
            <Button
              variant="contained"
              className={classes.button}
              onClick={() => handleChangeSection(-1)}
              startIcon={<ArrowBackIcon />}
            >
              Previous Section
            </Button>
          ) : (
            ""
          )}

          {/* NEXT SECTION */}
          {currentSection < sections.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => handleChangeSection(1)}
              endIcon={<ArrowForwardIcon />}
            >
              Next Section
            </Button>
          ) : (
            ""
          )}
        </div>
        <div className={classes.buttonBox}>
          {/* BACK */}
          <Button
            variant="contained"
            className={classes.button}
            component={Link}
            to="/"
            onClick={() => handleSubmitAnswers(false)}
            startIcon={<SaveIcon />}
          >
            Save & Exit
          </Button>

          {currentSection === sections.length - 1 ? (
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              component={Link}
              to="/"
              onClick={() => handleSubmitAnswers(true)}
              startIcon={<DoneIcon />}
            >
              I'm Finished
            </Button>
          ) : (
            ""
          )}
        </div>
      </div>
      <AssessmentProgressBar assessmentId={assessment._id} />
    </Container>
  );
};

const mapDispatchToProps = {
  submitAnswers,
  fetchAnswers,
  sendFlash,
};

const mapStateToProps = (state) => {
  const assessmentId = "interview";
  const answers = state.answers.byId[assessmentId];
  console.log(state.assessments);
  return {
    user: state.user,
    assessment: state.assessments.byId[assessmentId],
    answersLoading: answers ? answers.loading : false,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Interview);
