import React from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
// MUI Imports
import { FormControl, FormLabel, TextField, Paper } from "@mui/material";

// My Imports
import { shouldQuestionShow, getAnswer } from "./helpers";

// Actions
import { updateAnswer } from "../../../actions/index";
import HoverDetails from "../HoverDetails";
import MultiTextExtension from "./MultiTextExtension";

const useStyles = makeStyles({
  textField: {
    display: "block",
  },
});

const MultiText = (props) => {
  const classes = useStyles();

  const { number, style, item, answer } = props;
  const textFields = item.textFields;

  let rows = 0;
  let multiline = false;
  if (item.lines) {
    multiline = true;
    rows = item.lines;
  }

  // const handleTextChange = (index, event) => {
  //   const questionNum = event.target.name;
  //   const value = event.target.value;
  //   const answer = {
  //     index: index,
  //     value: value,
  //   };
  //   validate(index, event);
  //   props.updateAnswer(
  //     props.userId,
  //     props.assessmentId,
  //     questionNum,
  //     answer,
  //     "multiText"
  //   );
  // };
  // const validate = (index, event) => {
  //   const validationErrors = [];
  //   const characterLimit = textFields[index].characterLimit;
  //   const text = event.target.value;

  //   // Required
  //   if (text.length < 1) {
  //     validationErrors.push("Field is required.");
  //   }

  //   // Character Limit
  //   if (characterLimit && text.length > characterLimit) {
  //     validationErrors.push(
  //       `Text exceeds ${characterLimit} character limit by ${
  //         text.length - characterLimit
  //       } characters.`
  //     );
  //   }

  //   // Number
  //   if (item.validation === "number" && !validator.isInt(text)) {
  //     validationErrors.push("Not a valid number.");
  //   }

  //   if (validationErrors.length > 0) {
  //     setErrors([...validationErrors]);
  //   } else {
  //     setErrors(false);
  //   }
  // };
  return (
    <Paper className={props.questionStyles}>
      <FormControl>
        <FormLabel>
          {item.question} <HoverDetails description={item.description} />{" "}
        </FormLabel>
      </FormControl>
      <div>
        {textFields.map((field, index) => {
          // let answerText;
          // if (answer === []) {
          //   answerText = "";
          // } else if (answer[index]) {
          //   answerText = answer[index];
          // } else {
          //   answerText = "";
          // }
          return (
            <MultiTextExtension
              index={index}
              field={field}
              multiline={multiline}
              rows={rows}
              // answerText={answerText}
              number={number}
              textFields={textFields}
              item={item}
              userId={props.userId}
              assessmentId={props.assessmentId}

            />
            // <TextField
            //   key={index}
            //   label={field.label}
            //   fullWidth={false}
            //   multiline={multiline}
            //   minRows={rows}
            //   className={classes.textField}
            //   value={answerText}
            //   onChange={(event) => handleTextChange(index, event)}
            //   name={`${number}`}
            // />
          );
        })}
      </div>
    </Paper>
  );
};

const mapStateToProps = (state, ownProps) => {
  const answers = state.answers.byId["interview"];

  return {
    answer: getAnswer(answers, ownProps, []),
    show: shouldQuestionShow(answers, ownProps),
  };
};

const mapDispatchToProps = {
  updateAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(MultiText);
