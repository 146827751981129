import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { colors } from '../../constants/colors';
import { useQuery } from '@apollo/client';
import { makeStyles } from '@mui/styles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// My Components
import Centered from "../layout/Centered";
import Assessment  from "../layout/Assessment";
import HappyBox from "../layout/HappyBox";
import CustomTooltip from "../layout/CustomTooltip";
import CalendlyAppointmentList from "../layout/CalendlyAppointmentList";

// GQL Query Imports
import { GET_INITIAL_DATA } from '../../constants/gql-queries/dashboard';

// MUI Imports
import {
  Typography, CircularProgress, Container, List, LinearProgress, Button, Dialog,
  DialogActions, DialogContent, DialogTitle, useMediaQuery
} from '@mui/material';

// Icons
import MobileOffIcon from '@mui/icons-material/MobileOff';
import SmileIcon from '@mui/icons-material/InsertEmoticon';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';
import InfoIcon from '@mui/icons-material/Info';

// Actions
import { resendVerificaitonEmail, sendFlash } from '../../actions/index';

const useStyles = makeStyles({
  root: {
    backgroundColor: colors.offWhite
  },
  fullWidthBox: {
    display: 'flex',
    '& .leftIcon': {
      padding: '30px',
      paddingRight: '0px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      '& svg': {
        fontSize: '3.5em'
      }
    },
    '& .subtitle': {
      color: 'rgb(119, 119, 119)'
    },
    '& .rightContent': {
      padding: '30px',
      flex: '1 1 auto'
    }
  },
  heading: {
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      fontSize: '1em',
      marginRight: '20px'
    }
  },
  twoColumns: {
    display: 'flex',
    '& > div': {
      display: 'flex',
      flexDirection: 'column',
      flex: '1 0 50%',
      // backgroundColor: 'rgba(206, 254, 188, 0.49)',
    },
    '& .left': {
      paddingRight: '20px'
    }
  },
  popupBar: {
    backgroundColor: 'rgb(255 241 201)',
    display: 'flex',
    flex: '1 1 auto',
    padding: '10px',
    justifyContent: 'center',
    '& p': {
      fontSize: '1em'
    }
  },
  mobileWarningTitle: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '10px'
    }
  },
  progressBar: {
    height: '15px',
    borderRadius: '15px',
    '& div': {
      borderRadius: '15px'
    }
  }
});

const Dashboard = (props) => {
  const classes = useStyles();
  const [ mobileWarning, setMobileWarning ] = useState(true)

  const assessments = props.assessments.byId
  let planId

  // get user's planId for display logic
  try {
    planId = props.user?.plans[0]?._id
  } catch (error) {
    planId = null
  }

  const checkAllAssesmentsCompleted = () => {
    let completed = true
    if (assessments) {
      Object.keys(assessments).forEach(assessment => {
        if (!assessments[assessment].completed) {
          completed = false
        }
      })
    } else completed = false
    return completed
  }

  const allAssesmentsCompleted = checkAllAssesmentsCompleted()

  // Load Contentful Data (instructions & assessment data)
  const { loading, error, data } = useQuery(GET_INITIAL_DATA);
  // console.log('loading:', loading, 'data:', data)

  useEffect(() => {
    // redirect to /admin for admins in production
    if (props.user.admin && process.env.NODE_ENV === 'production') {
      props.history.push("/admin");
    }
    // Display verified email confirmation notice
    if (props.location.search === '?verified') {
      props.sendFlash('Thanks for verifying your email :)', 'success')
    }
    // Display order confirmation after redirect
    if (props.location.search === '?success=true') {
      props.sendFlash("Order placed! You will receive an email confirmation.", "success");
    }
  }, [props.user]);

  // Display warning if user email address isn't verified
  const VerifyEmailBanner = () => {
    if ('isVerified' in props.user && !props.user.isVerified) {
      return (
        <div className={classes.popupBar}>
          <Typography>
            Please check your inbox: We sent you a link to verify your email address | &nbsp;
            <Link
              to='#'
              onClick={() => props.resendVerificaitonEmail()}>
              resend
            </Link>
          </Typography>
        </div>
      )
    } else return null
  }

  const WelcomeBox = () => (
    <HappyBox className={classes.fullWidthBox} padding='0px'
      styles={{ marginTop: '40px' }}
    >
      <div className='leftIcon'>
        <SmileIcon />
      </div>
      <div className='rightContent'>
        <Typography variant="h4">
          <b>Hi, {props.user.firstName}</b>
        </Typography>
        <Typography variant="h5" className='subtitle'>
          Welcome to your Integrative Career Assessment!
        </Typography>
      </div>
    </HappyBox>
  )

  const ProgressBox = () => (
    <HappyBox className={classes.fullWidthBox} padding='0px'>
      <div className='leftIcon'>
        <AssignmentTurnedInIcon />
      </div>
      <div className='rightContent'>
        <Typography variant="h4" className={classes.heading} gutterBottom>
          <b>Your Progress</b>
          <CustomTooltip placement='right' arrow
            title='This bar represents your overall progress completing the Career Assessment'
          >
            <InfoIcon style={{
              fontSize: '0.7em',
              marginLeft: '10px',
              opacity: 0.3
            }} />
          </CustomTooltip>
        </Typography>
        <LinearProgress variant="determinate"
          className={classes.progressBar}
          value={calcGlobalProgress()}
        />
      </div>
    </HappyBox>
  )

  const Instructions = () => {
    const instructionsData = data ? data.instructions.items
      .find(item => item.id === 'dashboard-instructions').instructions : false

    const instructionsText = instructionsData ? documentToReactComponents(instructionsData.json) : 'loading...'

    if (allAssesmentsCompleted) {
      return (
        <HappyBox>
          <Typography variant='h4' gutterBottom>
            <b>Your Results Are Ready!</b>
          </Typography>
          <Typography paragraph>
            You did it! Feels good, doesn't it? Click the button below to check out your results.
          </Typography>

          { planId === 'premium' &&
            <Typography paragraph>
              You can now schedule your Results Session in the Appointments section below.
            </Typography>
          }

          <Button variant="contained" component={Link} to="/results"
            color="primary" fullWidth
          >
            View Results
          </Button>
        </HappyBox>
      )
    } else {
      return (
        <HappyBox>
          <Typography variant='h4' className={classes.heading} >
            <HelpOutlineIcon />
            <b>Instructions</b>
          </Typography>
          <Typography component='div'>
            { instructionsText }
          </Typography>

          {!props.user.consent && planId !== 'free' &&
            <Typography style={{ marginTop: '10px' }}>
              Please start with&nbsp;
              <Link to='/assess/consent'>Informed Consent</Link>. We can't do anything else until we have your consent.
            </Typography>
          }
        </HappyBox>
      )
    }
  }

  const AssessmentsList = () => (
    <HappyBox>
      <Typography variant='h4' gutterBottom>
        <b>Your Assessments</b>
      </Typography>

      <List>
        {Object.keys(props.assessments.byId).map((id, index) => {
          const assessment = props.assessments.byId[id]

          // Logic to determine if an assessment should be disabled
          const disabled = () => {
            if (assessment.completed) {
              return true // disable
            } else {
              if (planId === 'free') {
                return false
              } else {
                if (!props.user.consent) {
                  if (assessment._id === 'consent') {
                    return false // enable
                  } else {
                    return true // disable
                  }
                }
              }
            }
          }

          // Logic to determine if an assessment should greyedOut
          const assessmentGreyedOut = () => {
            if (planId === 'free') {
              return false
            } else {
              if (!props.user.consent) {
                if (assessment._id === 'consent') {
                  return false // enable
                } else {
                  return true // disable
                }
              }
            }
          }

          return(
            <Assessment
              key={index}
              contentfulData={getAssessmentContentfulData(assessment._id)}
              name={assessment.name}
              description={assessment.description}
              completed={assessment.completed}
              disabled={disabled()}
              greyedOut={assessmentGreyedOut()}
              percentComplete={ !assessment.questionsAnswered ? 0 : (
                Math.round((assessment.questionsAnswered / assessment.totalQuestions * 100) * 100) / 100
              )}
              link={`/assess/${assessment._id}`} // id doubles as link url
            />
          )
        })}
      </List>
    </HappyBox>
  )

  const AppointmentList = () => {
    if (planId !== 'free') {
      return (
        <HappyBox>
          <Typography variant='h4' className={classes.heading} gutterBottom>
            <EventNoteIcon />
            <b>Your Appointments</b>
          </Typography>

          <Typography>
            {planId === 'essential' ?
              "Your plan comes with a Results Session appointment that needs to be scheduled! You'll be prompted to schedule the appointment after you've completed all your assessments."
            :
              "Your plan comes with appointments that need to be scheduled. Please schedule your interview session ASAP. You'll be prompted to schedule your results session after you've completed all your assessments."
            }
          </Typography>

          <CalendlyAppointmentList
            planId={planId}
            user={props.user}
            allAssesmentsCompleted={allAssesmentsCompleted}
          />

        </HappyBox>
      )
    } else return null
  }

  const UpgradeBox = () => {
    const upgradeData = data ? data.instructions.items
      .find(item => item.id === 'dashboard-upgrade').instructions : false

    const wantMoreText = upgradeData ? documentToReactComponents(upgradeData.json) : 'loading...'

    // show upgrade box as long as plan is not premium
    if (planId !== 'premium') {
      return (
        <HappyBox>
          <Typography variant='h4' className={classes.heading}>
            <OfflineBoltIcon />
            <b>Want More?</b>
          </Typography>
          <Typography paragraph component='div'>
            { wantMoreText }
          </Typography>
          <Button variant="contained" component={Link} to="/plans"
            color="primary" fullWidth
          >
            Upgrade Now
          </Button>
        </HappyBox>
      )
    } else return null
  }

  const MobileDeviceWarning = () => {
    if (useMediaQuery('(max-width:780px)')) {
      return (
        <Dialog
          open={mobileWarning}
          onClose={() => setMobileWarning(false)}
        >
          <DialogTitle disableTypography className={classes.mobileWarningTitle}>
            <MobileOffIcon />
            <Typography variant='h6'>
              Mobile Device Warning
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Typography color="textSecondary">
              Our app is not yet optimized for mobile devices. We strongly suggest switching to a laptop or desktop device. Continue using the app on mobile at your own risk.
            </Typography>
            <br/>
            <Typography color="textSecondary">
              - The Wanderlust Team
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setMobileWarning(false)} color="primary">
              Got It
            </Button>
          </DialogActions>
        </Dialog>
      )
    } else {
      return null
    }
  }

  const getAssessmentContentfulData = (id) => {
    if (data) {
      // console.log(data.assessments.items)
      const assessments = data.assessments.items
      const assessmentData = assessments.find(assessment => assessment.id === id)
      // console.log(assessmentData)
      return assessmentData
    } else return false
  }

  // calculate global progress (for main progress bar)
  // by adding up totalQuestions and questionsAnswered for each assessment
  const calcGlobalProgress = () => {
    let totalQuestions = 0
    let totalAnswered = 0
    for (var id in assessments) {
      const assessment = assessments[id]
      if (assessment._id === 'interview' && assessment.completed) {
        totalQuestions += assessment.totalQuestions
        totalAnswered += assessment.totalQuestions
      } else {
        totalQuestions += assessment.totalQuestions
        totalAnswered += assessment.questionsAnswered
      }
    }
    const globalProgress = Math.round((totalAnswered/totalQuestions * 100) * 100) / 100
    return globalProgress
  }

  if (!props.user.loading && assessments) {

    // DASHBOARD
    return(
      <div className={classes.root}>
        <VerifyEmailBanner />

        <Container maxWidth='md'>
          <WelcomeBox />
          <ProgressBox />

          {/* MAIN */}
          <div className={classes.twoColumns}>

            {/* Left */}
            <div className='left'>
              <AssessmentsList />
              {/* { planId === 'premium' && <Appointments /> } */}
              <UpgradeBox />
            </div>

            {/* Right */}
            <div className='right'>
              <Instructions />
              {/* { planId !== 'premium' && <Appointments /> } */}
              {/* <AppointmentList /> */}
            </div>

          </div>
        </Container>
        <MobileDeviceWarning />
      </div>
    )
  } else {
    return(

      // SPINNER
      <Centered>
        <CircularProgress />
      </Centered>
    )
  }
}

const mapDispatchToProps = {
  resendVerificaitonEmail,
  sendFlash
};

const mapStateToProps = state => ({
  auth: state.auth,
  user: state.user,
  assessments: state.assessments
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Dashboard));
