import React from 'react';
import { makeStyles } from "@mui/styles";
import { motion } from "framer-motion"

// MUI Imports
import { Typography } from '@mui/material';

// My Imports
import CustomTooltip from "../layout/CustomTooltip";

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px'
  },
  boxContainer: {
    flex: '0 0 auto',
    backgroundColor: 'rgb(224, 224, 224)',
    height: '100px',
    width: '100px',
    borderRadius: '10px',
    overflow: 'hidden',
    margin: '5px'
  },
  innerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  letterBox: {
    // backgroundColor: 'rgba(255, 0, 36, 0.1)',
    flex: '0 0 100%',
    height: '100%',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2.8em',
    fontWeight: 400
  },
  // container: {
  //   flex: '0 0 100px',
  //   marginBottom: '20px',
  //   // justifyContent: 'center'
  // },
  // description: {
  //   display: 'flex',
  //   textAlign: 'center',
  //   justifyContent: 'center'
  // }
});

const MbtiLetterBoxes = (props) => {
  const { type, slots } = props
  const classes = useStyles();
  // console.log('slots', slots)

  const EI = type.charAt(0)
  const SN = type.charAt(1)
  const TF = type.charAt(2)
  const JP = type.charAt(3)

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  }

  const Box = (props) => {
    const { slot, value, details } = props

    let end = -100

    switch(slot) {
      case 'EI':
        end = value === 'E' ? 0 : -100
        break;
      case 'SN':
        end = value === 'S' ? 0 : -100
        break;
      case 'TF':
        end = value === 'T' ? 0 : -100
        break;
      case 'JP':
        end = value === 'J' ? 0 : -100
        break;
      default:
        end = 0
        break;
    }

    const item = {
      hidden: { y: -200 },
      show: { y: end }
    }

    return(
      <CustomTooltip arrow placement='top'
        title={
          <>
            <Typography variant='h6' gutterBottom>
              {details.name}
            </Typography>
            {details.description}
          </>
        }
      >
        <div className={classes.boxContainer}>
          <motion.div className={classes.innerContainer}
            variants={item}
          >
            <div className={classes.letterBox}>
              {slot.charAt(0)}
            </div>
            <div className={classes.letterBox}>
              {slot.charAt(1)}
            </div>
          </motion.div>
        </div>
      </CustomTooltip>
    )
  }

  return(
    <motion.div className={classes.root}
      variants={container}
      initial="hidden"
      animate="show"
    >
      <Box slot={'EI'} value={EI} details={slots.find(slot => slot.id === 'EI')} />
      <Box slot={'SN'} value={SN} details={slots.find(slot => slot.id === 'SN')} />
      <Box slot={'TF'} value={TF} details={slots.find(slot => slot.id === 'TF')} />
      <Box slot={'JP'} value={JP} details={slots.find(slot => slot.id === 'JP')} />
    </motion.div>
  )
}

export default MbtiLetterBoxes;
