import React from 'react';
import { makeStyles } from "@mui/styles";
import { globalStyles } from './constants/globalStyles';
import { Mixpanel } from "../../utils/mixpanel";
import Plans from '../main/Plans';

import orangeImg from "../../images/graphics/untitled_design_13.png";
import blueImg from "../../images/graphics/untitled_design_16.png";

// MUI Imports
import {
  Container, Typography, Button
} from '@mui/material';

const useGlobalStyles = makeStyles({
  ...globalStyles
});

const useStyles = makeStyles({
  root: {
    padding: '80px 0',
    minHeight: '500px',
    backgroundColor: '#F3F5F5',
  },
  sectionHeader: {
    minHeight: '145px',
    backgroundColor: '#6EC1E40D',
    backgroundImage: `url(${orangeImg})`,
    backgroundSize: '14vw auto',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center left',
    alignContent: 'center'
  }, 
  plans: {
    backgroundImage: `url(${blueImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
  },
  container: {
    padding: '200px 0 200px 0',
    display: 'flex',
    //justifyContent: 'space-around',
    //alignItems: 'center',

  },
  help: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '80px',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
});

const OurPlans = (props) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const handlePlanSelect = (planName, planId, price) => {
    Mixpanel.track("Chose a Plan", {
      name: planName,
      id: planId,
      price: price
    });

    props.history.push('/register', {
      planName: planName,
      planId: planId,
      price: price
    });
  }

  return(
    <div className={`${classes.root}`}>
      <div className={`${classes.sectionHeader}`}>
        <Typography variant='h1' sx={{ padding: '0 25%', fontWeight: '600'}}>Let's Get Started</Typography>
      </div>
      <div className={`${classes.plans}`}>
        <Container maxWidth='xl' className={classes.container}>
          <Plans cta='Get Started' onSelect={handlePlanSelect} />
        </Container>
      </div>
      <div className={classes.help}>
        <div>
          <Typography variant='h6' gutterBottom>
            Not sure what plan is best for you?
          </Typography>
          {/* <Button variant="contained" component={Link} to="/contact" color="primary" size='large'>
            Talk to an Expert
          </Button> */}
          <Button variant="contained" color="purple" size='large'
            onClick={props.handleOpenContactForm}
          >
            Talk to an Expert
          </Button>
        </div>
      </div>
    </div>
  )
}

export default OurPlans;
