import React from 'react';
import { makeStyles } from "@mui/styles";

// MUI Imports
import { Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

// Icons
import SearchIcon from '@mui/icons-material/Search';
import RocketLaunchOutlinedIcon from '@mui/icons-material/RocketLaunchOutlined';
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';

const useStyles = makeStyles({
  root: {
    minHeight: '500px',
    backgroundColor: '#F3F5F5',
    alignContent: 'center',
    '@media (max-width: 780px)' : {
      marginTop: '40px'
    },
  },
  step: {
    backgroundColor: '#E2DBEA',
    borderRadius: '10px',
    textAlign: 'center',
    margin: '10px',
    padding: '0 30px',
    height: '270px',
    maxWidth: '433px',
    '& h3': {
      fontSize: '1.9rem',
      lineHeight: '1.3em',
      //padding: '0px 0px '
    }
  },
  stepText: {
    padding: '20px 0'
  },
  materialIcon: {
    position: 'relative',
    top: '-30px',
    height: '50px',
    width: '50px',
    margin: '0px auto'
  },
});

const HowItWorks = (props) => {
  const classes = useStyles();

  const { data } = props
  const steps = data.contentCollection.items

  return(
    <div className={classes.root}>
      <Grid container="true" justifyContent={'center'}>
        <Grid item>
          <div className={classes.step}>
            <SearchIcon sx={{fontSize: 55}} className={classes.materialIcon} />
            <Typography variant='h3' align='left'>
              {steps[0].name}
            </Typography>
            <Typography className={classes.stepText} align='left' color="textSecondary">
              {steps[0].description}
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <div className={classes.step}>
            <InsightsOutlinedIcon sx={{fontSize: 56}} className={classes.materialIcon} />
            <Typography variant='h3' align='left'>
              {steps[1].name}
            </Typography>
            <Typography className={classes.stepText} align='left' color="textSecondary">
              {steps[1].description}
            </Typography>
          </div>
        </Grid>
        <Grid item>
          <div className={classes.step}>
            <RocketLaunchOutlinedIcon sx={{fontSize: 55}} className={classes.materialIcon} />
            <Typography variant='h3' align='left'>
              {steps[2].name}
            </Typography>
            <Typography className={classes.stepText} align='left' color="textSecondary">
              {steps[2].description}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default HowItWorks;
