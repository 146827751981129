import React from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";

// MUI Imports
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Paper,
} from "@mui/material";

// My Imports
import { shouldQuestionShow } from "./helpers";

// Actions
import { updateAnswer } from "../../../actions/index";
import HoverDetails from "../HoverDetails";
import { NotShowingBubbles } from "../../../constants/notShowingBubble";

const useStyles = makeStyles({
  text: {
    width: "200px",
    "& input": {
      paddingTop: "12px",
    },
  },
});

const Other = (props) => {
  const classes = useStyles();
  const { selected, answer, number } = props;

  if (selected) {
    return (
      <TextField
        id="1"
        name={`${number}`}
        label={false}
        fullWidth={false}
        variant="filled"
        className={classes.text}
        placeholder="Please Elaborate"
        value={answer.other}
        onChange={props.handleTextChange}
      />
    );
  } else {
    return "Other";
  }
};

const SelectOne = (props) => {
  const classes = useStyles();

  const { number, style, item, userId, assessmentId, answer } = props;

  const handleRadioChange = (event) => {
    const questionNum = event.target.name;
    const answer = {
      selection: event.target.value,
      other: "",
    };
    props.updateAnswer(userId, assessmentId, questionNum, answer, "radio");
  };

  const handleTextChange = (event) => {
    const questionNum = event.target.name;
    const answer = {
      selection: props.answer.selection,
      other: event.target.value,
    };
    props.updateAnswer(userId, assessmentId, questionNum, answer, "radio");
  };

  if (props.show) {
    return (
      <Paper className={props.questionStyles}>
        <FormControl>
          <FormLabel>
            {item.question}
            {NotShowingBubbles.includes(item.question) ||
            item.description === "" ? (
              ""
            ) : (
              <HoverDetails description={item.description} />
            )}
          </FormLabel>
          <RadioGroup
            name={`${number}`}
            value={answer.selection}
            onChange={handleRadioChange}
          >
            {Object.keys(item.choices).map((choice) => {
              return (
                <FormControlLabel
                  key={choice}
                  value={choice}
                  control={<Radio />}
                  label={
                    choice !== "other" ? (
                      item.choices[choice]
                    ) : (
                      <Other
                        answer={answer}
                        handleTextChange={handleTextChange}
                        selected={choice === answer.selection ? true : false}
                        number={number}
                      />
                    )
                  }
                />
              );
            })}
          </RadioGroup>
        </FormControl>
      </Paper>
    );
  } else return "";
};

const mapStateToProps = (state, ownProps) => {
  let answers = state.answers.byId["interview"];
  // console.log(answers)
  if (!answers || !answers.answers || answers.answers.length === 0) {
    answers = false;
  }
  let answer = null;

  // set answer to empty string if doesnt exist
  if (answers && answers.answers[ownProps.number - 1]) {
    answer = answers.answers[ownProps.number - 1];
  } else {
    answer = { selection: "" };
  }

  return {
    answer: answer,
    show: shouldQuestionShow(answers, ownProps),
  };
};

const mapDispatchToProps = {
  updateAnswer,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectOne);
