import React, { Fragment, useState } from "react";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { makeStyles } from '@mui/styles';

import EditIcon from "../../../images/icons/edit-outline-black.svg";
import DeleteIcon from "../../../images/icons/delete-black.svg";
import { connect } from "react-redux";
import { deleteRecord } from "../../../actions";
import ConfirmDeleteModal from "./ConfirmDeleteModal";

// Styles
const useStyles = makeStyles((theme) => ({
  tableItem: {
    fontFamily: "Lato",
    textAlign: "center",
    minHeight: "110px",

    borderStyle: "solid",
    borderColor: "#6C757D",
    borderTop: 0,
    borderRight: "1px",
    borderLeft: "1px",

    fontSize: "12px",
    fontWeight: "400",
    color: "#383050",
  },

  removeBtnClasses: {
    height: "fit-content",
    backgroundColor: "#fff",
    border: 0,
    padding: 0,
    cursor: "pointer",
  },
  descriptionContainer: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 5,
    transition: "all 0.3s ease",
  },
  expandedDescription: {
    WebkitLineClamp: "unset",
  },
}));

const SingleAddRecord = ({
  record,
  setUpdatedRecordId,
  setOpenConfirmModal,
  ...props
}) => {
  // const { record } = props;
  const classes = useStyles();
  const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);

  const rows = [
    {
      id: "jobTitle",
      value: record.jobTitle || "",
    },
    { id: "familyType", value: record.familyType },
    { id: "secondaryFamily", value: record.secondaryFamily },
    { id: "SII_1", value: record.SII_1 },
    { id: "SII_2", value: record.SII_2 },
    { id: "SII_3", value: record.SII_3 },
    { id: "MBTI_1", value: record.MBTI_1 },
    { id: "MBTI_2", value: record.MBTI_2 },
    { id: "MBTI_3", value: record.MBTI_3 },
    {
      id: "salary",
      value: record.salary <= "1" ? "<$100k" : "$100k+",
    },
    {
      id: "stress_level",
      value: record.stress_level === "L" ? "Low" : "High",
    },
    { id: "min_edu", value: record.min_edu },
    { id: "description", value: record.description },
  ];

  const UpdateRecordHandler = () => {
    setUpdatedRecordId(record._id);
    setOpenConfirmModal(true);
  };

  return (
    <Fragment>
      <TableRow hover role="checkbox" tabIndex={-1}>
        {rows.map((column) => {
          return (
            <TableCell
              key={column.id}
              className={`${classes.tableItem}`}
              style={{
                position: column.id === "jobTitle" ? "sticky" : "static",
                left: column.id === "jobTitle" ? 0 : "auto",
                zIndex: column.id === "jobTitle" ? 2 : 1,
                minWidth: column.id === "description" ? "315px" : "auto",
                // background: column.id === "jobTitle" ? "white" : "inherit",
                background: "white",

                borderLeft: column.id === "jobTitle" ? "1px solid #6C757D" : 0,
                borderRight: column.id === "description" && 0,
                paddingTop: column.id === "description" ? "10px" : "46px",
                paddingBottom: column.id === "description" ? "10px" : "46px",
                whiteSpace: column.id === "description" ? "normal" : "nowrap",
              }}
            >
              {column.id === "description" ? (
                <div className={classes.descriptionContainer}>
                  {column.value}
                </div>
              ) : (
                column.value
              )}
            </TableCell>
          );
        })}

        {/* edit delete column */}
        <TableCell
          className={classes.tableItem}
          style={{
            position: "sticky",
            display: "flex",
            alignItems: "center",
            gap: "6px",
            paddingLeft: "33px",
            paddingRight: "33px",

            right: 0,
            zIndex: 2,
            background: "white",
            border: "1px solid #6C757D",
            borderTop: 0,
          }}
        >
          <Tooltip title="Edit" placement="top">
            <button
              className={classes.removeBtnClasses}
              onClick={UpdateRecordHandler}
            >
              <img src={EditIcon} style={{ width: "24px" }} alt="edit-icon" />
            </button>
          </Tooltip>
          <Tooltip title="Delete" placement="top">
            <button
              className={classes.removeBtnClasses}
              // onClick={DeleteRecordHandler}
              onClick={() => setOpenConfirmDeleteModal(true)}
            >
              <img
                src={DeleteIcon}
                style={{ width: "24px" }}
                alt="delete-icon"
              />
            </button>
          </Tooltip>
        </TableCell>
      </TableRow>
      {openConfirmDeleteModal && (
        <ConfirmDeleteModal
          onClose={setOpenConfirmDeleteModal}
          record={record}
        />
      )}
    </Fragment>
  );
};

const mapDispatchToProps = {
  // deleteRecord,
};

const mapStateToProps = (state) => {
  return {
    // successMessage: state.records.successMessage,
    // loading: state.records.addRecordLoading,
    // filters: state.records.filters,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SingleAddRecord);
