import React from 'react';
import { makeStyles } from "@mui/styles";
import { useQuery } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// MUI Imports
import {
  Typography, Paper, Tooltip, CircularProgress
} from '@mui/material';

// My Imports
import Centered from "../layout/Centered";
import CustomTooltip from "../layout/CustomTooltip";
import HappyBox from "../layout/HappyBox";

// GQL Query Imports
import { GET_CARD_SORT_RESULTS } from './constants/queries';

const useStyles = makeStyles({
  root: {
    margin: '-5px',
    marginTop: '20px',
    marginBottom: '50px',
    display: 'flex',
    flexWrap: 'wrap'
  },
  title: {
    marginBottom: '20px'
  },
  box: {
    flex: '1 0 auto',
    margin: '5px'
  },
  listItem: {
    fontSize: '1.1em',
    cursor: 'default',
  }
});

const CareerValueResults = (props) => {
  const classes = useStyles();
  const { results } = props
  const { start, ...answers } = results.answers

  // Load Contentful Data
  const { loading, error, data } = useQuery(GET_CARD_SORT_RESULTS);
  // console.log('loading:', loading, 'data:', data)

  const renderStars = (categoryId) => {
    switch (categoryId) {
      case 'always':
        return '⭐⭐⭐⭐'
      case 'often':
          return '⭐⭐⭐'
      case 'sometimes':
        return '⭐⭐'
      case 'seldom':
        return '⭐'
      case 'never':
        return ' '
      default:
        return ''
    }
  }

  if (loading) {
   return(
     <Centered>
       <CircularProgress />
     </Centered>
   )
  } else {
    const assessment = data.cardSortResults.assessment
    // console.log(props)
    return(
      <>
        <HappyBox>
          <Typography variant='h4' gutterBottom>
            Career Values Card Sort
          </Typography>

          <Typography gutterBottom component='div'>
            { documentToReactComponents(assessment.longDescription.json) }
          </Typography>

          {/* { assessment.whyIsItUsed &&
            <>
              <Typography gutterBottom>
                Why Is It Used?
              </Typography>
              <Typography gutterBottom>
                { assessment.whyIsItUsed }
              </Typography>
            </>
          } */}

        </HappyBox>

        <div className={classes.root}>
          {
            Object.keys(answers).map((categoryId, index) => {
              const category = answers[categoryId]
              return (
                <HappyBox key={index} className={classes.box} marginBottom={'5px'}>
                  <Typography variant='h6'>
                    {renderStars(categoryId)}
                  </Typography>
                  <Typography variant='h6'>
                    {category.name}
                  </Typography>
                  {categoryId === 'always' ?
                    <ol>
                      {category.cards.map(card => {
                        return (
                          <CustomTooltip key={card.id}
                            title={card.description} arrow placement='right'
                          >
                            <li className={classes.listItem}>
                              {card.title}
                            </li>
                          </CustomTooltip>
                        )
                      })}
                    </ol> :
                    <ul>
                      {category.cards.map(card => {
                        return (
                          <CustomTooltip key={card.id}
                            title={card.description} arrow placement='right'
                          >
                            <li className={classes.listItem}>
                              {card.title}
                            </li>
                          </CustomTooltip>
                        )
                      })}
                    </ul>
                  }
                </HappyBox>
              )
            })
          }
        </div>
      </>
    )
  }
}

export default CareerValueResults
