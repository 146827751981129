import React, { useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import isEmpty from "is-empty";
import { motion } from "framer-motion"

// MUI Imports
import {
  Typography, Button, CircularProgress, Stack
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarIcon from '@mui/icons-material/Star';

import Centered from '../layout/Centered';

// Actions
import {
  fetchPlans, sendFlash, handlePaymentRedirect
} from '../../actions/index';


const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: '24px 24px',
    justifyContent: 'center',
    '@media (max-width: 780px)' : {
      flexDirection: 'column'
    },
  },
  plan: {
    width: '30%',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    borderRadius: '16px',
    marginBottom: '10px',
    padding: '48px',
    '@media (max-width: 780px)' : {
      width: '100%'
    },
  },
  planName: {
    marginBottom: '20px',
    color: '#7E80C6',
    fontWeight: 700
  },
  priceDiv: {
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    fontSize: '0.5rem',
    alignItems: 'center',
    gap: '10px'
  },
  price: {
    fontSize: '2.5rem',
    margin: '0',
    textAlign: 'left',
    fontWeight: 600
  },
  processingFeeNote: {
    fontSize: "10px", 
  },
  featuresStack: {
    display: "flex",
    alignItems: "center"
  },
  bullet: {
    fontSize: '0.9em',
    paddingLeft: '20px'
  },
  spacer: {
    height: "15px"
  }
});

const Plans = (props) => {
  const classes = useStyles();
  let { loading, plans, cta, userPlan } = props

  plans = plans.sort((a, b) => (a.order > b.order) ? 1 : -1)

  const location = window.location.pathname;

  // remove irrelevant plans (plan user currently has and cheaper plans)
  if (userPlan) {
    plans = plans.filter(plan => plan.price > userPlan.price)
  }

  // Load plans into redux
  useEffect(() => {
    if (!props.loading && plans.length === 0) {
      console.log('loading plans...')
      props.fetchPlans()
    }

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);
    if (query.get("canceled")) {
      props.sendFlash(
        "Order canceled -- continue to shop around and checkout when you're ready.", "warning"
      );
    }
  }, []);

  const calcPrice = (currentPlan, newPlan) => {
    if (!userPlan || currentPlan._id === 'free') {
      return newPlan.price
    } else {
      const alreadyPaid = currentPlan.price
      return newPlan.price - alreadyPaid
    }
  }

  if (!loading) {
    return(
      <div className={classes.root}>
        {plans.map((plan, index) => (
          <motion.div key={plan.name} className={classes.plan}
            whileHover={{
              y: -10,
              backgroundColor: 'rgba(255, 255, 255, 1)',
              // transition: { duration: 0.2 }
            }}
          >
            <div>
              <span gutterBottom align='left' className={classes.planName}>
                {plan.name}
              </span>
            </div>
            <div>
              <div className={classes.priceDiv}>
                <span align='center' className={classes.price} gutterBottom>
                  {`$${calcPrice(userPlan, plan) / 100}`}
                </span>
                <span>
                  {
                    plan._id != "free" ?
                      <p className={classes.processingFeeNote} color="textSecondary" >
                        + {plan.processingFeePercentage}% processing <br/>fee
                      </p>
                    : 
                    <div className={classes.spacer}></div>
                  }
                </span>
              </div>
              <Typography sx={{padding: '10px 0', minHeight:'80px'}}>{plan.tagline}</Typography>
              <Stack spacing={2}>
                {location != '/plans' && plan.features.map((feature, index) => (
                  feature.startsWith('Everything in the')?
                  <div className={classes.featuresStack}>
                    <StarIcon color="enfasis"></StarIcon>
                    <Typography color="textSecondary" fontWeight={600} className={classes.bullet}>
                      {feature}
                    </Typography>
                  </div>
                  :
                  <div className={classes.featuresStack}>
                    <CheckCircleIcon color="enfasis"></CheckCircleIcon>
                    <Typography color="textSecondary" className={classes.bullet}>
                      {feature}
                    </Typography>
                  </div>
                ))}
              </Stack>
              <Button 
                variant="contained" color="purple" size='large' fullWidth sx={{margin: '20px'}}
                onClick={() => props.onSelect(plan.name, plan._id, `$${calcPrice(userPlan, plan) / 100}`)}
              > {cta}</Button>
            </div>
          </motion.div>
        ))}
      </div>
    )
  } else {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    )
  }
}

const mapDispatchToProps = {
  fetchPlans,
  sendFlash,
  handlePaymentRedirect
};

const mapStateToProps = state => {
  const user = isEmpty(state.user) ? false :
    state.user.plans ? state.user.plans[0] : false
  return {
    userPlan: user,
    loading: state.plans.loading,
    plans: state.plans.allPlans
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Plans));
