import { colors } from "@mui/material";
import { colors as custom_colors } from './colors';

export const theme = {
  palette: {
    primary: {
      main: custom_colors.blue
    },
    secondary: {
      main: custom_colors.pink
    },
    error: {
      main: custom_colors.red
    },
    warning: {
      main: custom_colors.orange
    },
    info: {
      main: custom_colors.blue
    },
    success: {
      main: custom_colors.green
    },
    test: {
      main: custom_colors.test
    },
    purple: {
      main: '#7d80c6',
      contrastText: "#ffffff"
    },
    orange: {
      main: '#bb7f67',
    },
    whiteButton: {
      main: colors.common.white,
      light: colors.blueGrey[50],
      dark: '#E2794B',
      contrastText: colors.blueGrey[900],
    },
    blueGrey: {
      main: colors.blueGrey[100],
      light: colors.blueGrey[50],
      dark: colors.blueGrey[900],
      contrastText: colors.blueGrey[900],
    },
    ochre: {
      main: '#E3D026',
      light: '#E9DB5D',
      dark: '#A29415',
      contrastText: '#242105',
    },
    enfasis: {
      main: '#9A512F'
    }
  },
  typography: {
    fontFamily: [
      '"Montserrat"',
      'sans-serif',
    ].join(','),
    h1: {
      fontWeight: 700,
      lineHeight: '1.4em',
      fontSize: '2.8rem',
    },
    h2: {
      fontWeight: 500,
      fontSize: '2rem'
    },
    h3: {
      fontSize: '1.88rem',
      lineHeight: '1.3em',
      fontWeight: 700,
    },
    loginH1: {
      fontFamily: [
        '"Bebas Neue"',
        'sans-serif',
      ].join(','),
      fontWeight: 500,
      fontSize: '5rem'
    },
    loginH2: {
      fontFamily: [
        '"Bebas Neue"',
        'sans-serif',
      ].join(','),
      fontSize: '4rem'
    },
    loginH3: {
      fontFamily: [
        '"Bebas Neue"',
        'sans-serif',
      ].join(','),
    },
    loginH4: {
      fontFamily: [
        '"Bebas Neue"',
        'sans-serif',
      ].join(','),
    },
    loginH5: {
      fontFamily: [
        '"Bebas Neue"',
      ].join(','),
    },
    loginH6: {
      fontFamily: [
        '"Bebas Neue"',
        'sans-serif',
      ].join(','),
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.85em",
        // color: "red",
        // backgroundColor: "rgba(0, 0, 0, 0.2)"
      }
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Bebas Neue',
          fontSize: '1.2rem'
        },
        text: {
          fontFamily: 'Lato',
          textTransform: 'none',
          fontSize: '1rem',
          //lineHeight: 1.5,
          fontWeight: 600
        }
      },
    },
  }
};
