import { produce } from 'immer';
import { SET_AUTH_STATUS } from "../actions/index";
const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false
};

export default (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {

      case SET_AUTH_STATUS:
        draft.isAuthenticated = !isEmpty(action.payload)
        if (action.userId) {
          draft.userId = action.userId
        }
        return draft

      default:
        break;
    }
  })
