import React, { useEffect } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";

// MUI Imports
import { CircularProgress } from "@mui/material";

// Actions
import { fetchUserResults } from "../../actions/index";

// My Imports
import Results from "../main/Results";
import Centered from "../layout/Centered";

const useStyles = makeStyles({
  root: {
    marginTop: "50px",
  },
});

const AdminResults = (props) => {
  const classes = useStyles();
  const userId = props.match.params.id;

  const { user } = props;

  const findAssessmentData = (assessmentId) => {
    if (user && user.assessmentData) {
      return user.assessmentData.find(
        (assessment) => assessment._id === assessmentId
      );
    } else return false;
  };

  const depression = findAssessmentData("depression-screening");
  const mb = findAssessmentData("myers-briggs");
  const neo = findAssessmentData("neo");
  const careerValues = findAssessmentData("career-values");

  console.log(mb);

  useEffect(() => {
    if (!user) {
      props.fetchUserResults(userId);
    }
  }, []);

  if (user) {
    return (
      <Results
        user={{
          firstName: user.firstName,
          lastName: user.lastName,
        }}
        assessments={{
          byId: {
            ...(depression && { "depression-screening": depression }),
            ...(mb && { "myers-briggs": mb }),
            ...(neo && { neo: neo }),
            ...(careerValues && { "career-values": careerValues }),
          },
        }}
        results={{
          byId: {
            ...(depression?.results && {
              "depression-screening": depression.results,
            }),
            ...(mb?.results && { "myers-briggs": mb.results }),
            ...(neo?.results && { neo: neo.results }),
          },
        }}
        careerValueAnswers={careerValues}
      />
    );
  } else {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    );
  }
};

const mapDispatchToProps = {
  fetchUserResults,
};

const mapStateToProps = (state, ownProps) => {
  const userId = ownProps.match.params.id;
  return {
    user: state.admin.userDetail[userId],
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminResults);
