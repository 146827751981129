export const globalStyles = {
  section: {
    padding: '50px 0'
  },
  "& .sectionTitle": {
    fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
    fontSize: '1234.9em',
    padding: '0px 20px',
    fontWeight: '700'
  }
}
