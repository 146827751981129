import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";

// MUI Imports
import {
  ListItemSecondaryAction,
  IconButton,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";

// Images
import logo from "../../images/logo_100.png";

// Icons
import StarBorder from "@mui/icons-material/StarBorder";
import ViewAgendaIcon from "@mui/icons-material/ViewAgenda";
import MoodIcon from "@mui/icons-material/Mood";
import BorderAllIcon from "@mui/icons-material/BorderAll";
import PersonIcon from "@mui/icons-material/Person";
import StarIcon from "@mui/icons-material/Star";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const drawerWidth = 300;

const useStyles = makeStyles({
  root: {},
  drawerContainer: {
    overflow: "auto",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  logo: {
    maxWidth: "180px",
    padding: "15px",
    paddingRight: "30px",
  },
  nested: {
    paddingLeft: "40px",
  },
});

const SidebarMenu = (props) => {
  const { view, assessments, handleScrollTo, handleNavSelect, elevateResults } =
    props;

  const classes = useStyles();
  const [navExpanded, setNavExpanded] = useState(false);
  // const [siiSelection, setSiiSelection] = useState(null);

  const depression = assessments.byId["depression-screening"];
  const neo = assessments.byId["neo"];
  const mb = assessments.byId["myers-briggs"];
  const careerValues = assessments.byId["career-values"];
  // const psychosocialCareerInterview = assessments.byId["interview"];

  const handleNavExpand = () => {
    setNavExpanded(!navExpanded);
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      {/* LOGO */}
      <div className={classes.logoContainer}>
        <Link to="/">
          <img src={logo} className={classes.logo} alt="Logo" />
        </Link>
      </div>

      {/* MENU */}
      <div className={classes.drawerContainer}>
        <Divider />
        <List>
          {/* Depression */}
          <ListItem
            button
            key="depression"
            id="depression-screening"
            onClick={handleNavSelect}
            selected={view === "depression-screening"}
            disabled={depression ? !depression.completed : true}
          >
            <ListItemIcon>
              <MoodIcon />
            </ListItemIcon>
            <ListItemText primary="Depression Screening" secondary="(BDI-II)" />
          </ListItem>

          <ListItem
            button
            key="sii"
            id="sii"
            onClick={(e) => {
              handleNavExpand();
              handleNavSelect(e);
            }}
            selected={view === "sii"}
            disabled={mb && mb.completed && elevateResults.sii ? false : true}
          >
            <ListItemIcon>
              <StarIcon />
            </ListItemIcon>
            <ListItemText
              primary="Strong Interest Inventory"
              secondary="(SII)"
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                onClick={handleNavExpand}
                disabled={
                  mb && mb.completed && elevateResults.sii ? false : true
                }
                // {(mb && mb.completed) || (elevateResults.sii) ? false : true}
                // disabled={(mb && !mb.completed) || !elevateResults.sii}
              >
                {navExpanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>

          {/* STRONG */}
          <Collapse in={navExpanded} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem
                button
                className={classes.nested}
                data="got"
                onClick={handleScrollTo}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="General Occupational Themes" />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                data="bis"
                onClick={handleScrollTo}
                // selected={siiSelection === 'bis'}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="Basic Interest Scales" />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                data="os"
                onClick={handleScrollTo}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="Occupational Scales" />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                data="pss"
                onClick={handleScrollTo}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="Personal Style Scales" />
              </ListItem>

              <ListItem
                button
                className={classes.nested}
                data="sci"
                onClick={handleScrollTo}
              >
                <ListItemIcon>
                  <StarBorder />
                </ListItemIcon>
                <ListItemText primary="Skills Confidence Inventory (SCI)" />
              </ListItem>
            </List>
          </Collapse>

          {/* MBTI */}
          <ListItem
            button
            key="mbti"
            id="mbti"
            onClick={handleNavSelect}
            selected={view === "mbti"}
            disabled={mb && mb.completed && elevateResults.mbti ? false : true}
          >
            <ListItemIcon>
              <BorderAllIcon />
            </ListItemIcon>
            <ListItemText
              primary="Myers Briggs Type Indicator-Form M"
              secondary="(MBTI)"
            />
          </ListItem>

          {/* NEO */}
          <ListItem
            button
            key="neo"
            id="neo"
            onClick={handleNavSelect}
            selected={view === "neo"}
            disabled={neo ? !neo.completed : true}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary="NEO Personality Inventory-3"
              secondary="(NEO PI-3)"
            />
          </ListItem>

          {/* Interview  */}

          {/* <ListItem
            button
            key="interview"
            id="interview"
            onClick={handleNavSelect}
            selected={view === "interview"}
            disabled={psychosocialCareerInterview ? !psychosocialCareerInterview.completed : true}
          >
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText
              primary="Interview"
              secondary="(Psychosocial Career Interview)"
            />
          </ListItem> */}

          {/* Career Values */}
          <ListItem
            button
            key="career-values"
            id="career-values"
            onClick={handleNavSelect}
            selected={view === "career-values"}
            disabled={!careerValues || !careerValues.completed ? true : false}
          >
            <ListItemIcon>
              <ViewAgendaIcon />
            </ListItemIcon>
            <ListItemText primary="Career Values Card Sort" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default SidebarMenu;
