import React, { useEffect, useState } from 'react'
import { makeStyles } from '@mui/styles'

// for date pickers
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { alpha, styled } from '@mui/styles'

import { connect } from 'react-redux'
import isEmpty from 'is-empty'
import moment from 'moment'
import { Link } from 'react-router-dom'

import UserPlan from './UserPlan'

import adminClasses from '../../styles/admin.module.css'

// My Imports
import Centered from '../layout/Centered'
import DetailView from './DetailView'

// MUI Imports
import {
	Typography,
	Container,
	Button,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	CircularProgress,
	FormControlLabel,
	Switch,
	LinearProgress,
	Chip,
	TableSortLabel,
	TextField,
	Box,
	IconButton,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

// Actions
import { getAllUsers, getFilteredUsers } from '../../actions/index'

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
	width: 300,
	color: 'green',
}))

const userPlans = [
	{
		id: 'essential',
		label: 'Essential',
		selected: false,
	},
	{
		id: 'premium',
		label: 'Premimum',
		selected: false,
	},
	{
		id: 'basic',
		label: 'Basic',
		selected: false,
	},
	{
		id: 'free',
		label: 'Free',
		selected: false,
	},
]
const descendingComparator = (a, b, orderBy, specialSort = false) => {
	if (specialSort === 'plans') {
		if (b[orderBy][0].order < a[orderBy][0].order) {
			return -1
		}
		if (b[orderBy][0].order > a[orderBy][0].order) {
			return 1
		}
	} else if (specialSort === 'assessmentsComplete') {
		// this one's not done. sorting by assessment data array length
		if (b.leanAssessmentData.length < a.leanAssessmentData.length) {
			return -1
		}
		if (b.leanAssessmentData.length > a.leanAssessmentData.length) {
			return 1
		}
	} else {
		if (b[orderBy] < a[orderBy]) {
			return -1
		}
		if (b[orderBy] > a[orderBy]) {
			return 1
		}
	}
	return 0
}

const getComparator = (order, orderBy, specialSort = false) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy, specialSort)
		: (a, b) => -descendingComparator(a, b, orderBy, specialSort)
}

const stableSort = (array, comparator) => {
	const stabilizedThis = array.map((el, index) => [el, index])
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0])
		if (order !== 0) return order
		return a[1] - b[1]
	})
	return stabilizedThis.map((el) => el[0])
}

const EnhancedTableHead = (props) => {
	const useStyles = makeStyles({
		root: {},
		visuallyHidden: {
			border: 0,
			clip: 'rect(0 0 0 0)',
			height: 1,
			margin: -1,
			overflow: 'hidden',
			padding: 0,
			position: 'absolute',
			top: 20,
			width: 1,
		},
	})

	const classes = useStyles()
	const { order, orderBy, onRequestSort } = props

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property)
	}

	const headCells = [
		{ id: 'firstName', disablePadding: false, label: 'First Name' },
		{ id: 'lastName', disablePadding: false, label: 'Last Name' },
		{
			id: 'admin',
			align: 'center',
			disablePadding: false,
			label: 'Admin',
			shy: true,
		},
		{ id: 'email', disablePadding: false, label: 'Email' },

		{ id: 'createdAt', disablePadding: false, label: 'Signed Up' },
		{ id: 'plans', disablePadding: false, label: 'Plan' },
		{
			id: 'isVerified',
			align: 'center',
			disablePadding: false,
			label: 'Verified',
			shy: true,
		},
		{
			id: 'assessmentsComplete',
			disablePadding: false,
			label: 'Assessments Completed',
		},
		{ id: 'viewResults', disablePadding: false, label: 'Results' },
		{ id: 'viewInterview', disablePadding: false, label: 'Interview' },
		{
			id: 'MbtiSciSync',
			disablePadding: false,
			label: 'Elevate Sync',
			shy: true,
		},
	]

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, index) => {
					if (headCell.shy && !props.advanced) {
						return null
					} else {
						return (
							<TableCell
								key={index}
								align={headCell.align}
								padding={headCell.disablePadding ? 'none' : 'normal'}
								sortDirection={orderBy === headCell.id ? order : false}>
								<TableSortLabel
									active={orderBy === headCell.id}
									direction={orderBy === headCell.id ? order : 'asc'}
									onClick={createSortHandler(headCell.id)}>
									{headCell.label}
									{orderBy === headCell.id ? (
										<span className={classes.visuallyHidden}>
											{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
										</span>
									) : null}
								</TableSortLabel>
							</TableCell>
						)
					}
				})}
			</TableRow>
		</TableHead>
	)
}

const Admin = (props) => {
	// const useStyles = makeStyles({
	// 	root: {
	// 		flex: '1 1 auto',
	// 		display: 'flex',
	// 		flexDirection: 'column',
	// 		// overflow: 'hidden',
	// 		// backgroundColor: 'rgb(251, 186, 186)'
	// 	},
	// 	container: {
	// 		dislay: 'flex',
	// 		flex: '0 1 auto',
	// 	},
	// 	top: {
	// 		display: 'flex',
	// 		flex: '0 1 auto',
	// 		justifyContent: 'space-between',
	// 		alignItems: 'center',
	// 	},
	// 	heading: {
	// 		marginTop: '20px',
	// 		marginBottom: '10px',
	// 	},
	// 	tableContainer: {
	// 		display: 'flex',
	// 		flex: '0 1 auto',
	// 		// maxHeight: '100%'
	// 	},
	// 	table: {},
	// 	bar: {
	// 		height: '10px',
	// 	},
	// })

	// const classes = useStyles()
	// const [dialogOpen, setDialogOpen] = useState(false)
	// const [selectedUserId, setSelectedUserId] = useState(null)

	// const [advanced, setAdvanced] = useState(false)
	// const [order, setOrder] = useState('desc')
	// const [orderBy, setOrderBy] = useState('createdAt')
	// const [users, setUsers] = useState(props.userList.users)
	// const [paramsUser, setParamsUser] = useState('')

	// const [allUsers, setAllUsers] = useState(props.userList.users)
	// const [plansSelectTheme, setPlansSelctTheme] = useState({
	// 	color: 'black',
	// 	bgColor: 'white',
	// })
	// const [usersCount, setUsersCount] = useState(0)

	// // selected plans

	// const [selectedPlans, setSelectedPlans] = useState([])

	// // date value
	// // const [value, setValue] = React.useState(new Date());

	// const [year, setYear] = useState(new Date())
	// const [monthYear, setMonthYear] = useState(new Date())

	// // Load user data on page load (via api)
	// useEffect(() => {
	// 	if (isEmpty(props.userList)) {
	// 		props.getAllUsers()
	// 	} else if (props.userList.users) {
	// 		setUsers(stableSort(props.userList.users, getComparator(order, orderBy)))
	// 		setAllUsers(stableSort(props.userList.users, getComparator(order, orderBy)))
	// 		setUsersCount(props.userList.users.length)
	// 	}
	// }, [props.userList])

	// const handleRequestSort = (event, property) => {
	// 	// console.log('SORT', event, property)
	// 	const isAsc = orderBy === property && order === 'asc'
	// 	const newOrder = isAsc ? 'desc' : 'asc'
	// 	let newOrderBy = property
	// 	console.log('sort property:', newOrderBy)
	// 	console.log('sort direction:', newOrder)
	// 	console.log(' ')
	// 	setOrder(newOrder)
	// 	setOrderBy(newOrderBy)
	// 	let specialSort = false

	// 	if (property === 'plans' || property === 'assessmentsComplete') {
	// 		specialSort = property
	// 	}
	// 	setUsers(stableSort(users, getComparator(newOrder, newOrderBy, specialSort)))
	// }

	// const handleSwitchChange = (event) => {
	// 	setAdvanced(event.target.checked)
	// }

	// const checkForMbResults = (user) => {
	// 	if (user.plans[0].assessments.includes('myers-briggs')) {
	// 		if (user.leanAssessmentData) {
	// 			const data = user.leanAssessmentData.find((assessment) => assessment.id === 'myers-briggs')
	// 			if (data && data.completed) {
	// 				if (data && data['MBTI'] === true && data['SII'] === true) {
	// 					return '✅'
	// 				} else {
	// 					return '❌'
	// 				}
	// 			} else {
	// 				return ''
	// 			}
	// 		}
	// 	} else {
	// 		return ''
	// 	}
	// }

	// const calcUserProgress = (user) => {
	// 	let assessmentsComplete = 0
	// 	if (user.leanAssessmentData) {
	// 		user.leanAssessmentData.forEach((assessment) => {
	// 			if (assessment.completed) {
	// 				assessmentsComplete += 1
	// 			}
	// 		})
	// 	}
	// 	const totalAssessments = user.plans[0].assessments.length
	// 	return (assessmentsComplete / totalAssessments) * 100
	// }

	// const handleRowClick = (event, userId) => {
	// 	// console.log('row clicked!', userId, 'event:', event)
	// 	setSelectedUserId(userId)
	// 	handleOpenDialog()
	// }

	// const handleOpenDialog = () => {
	// 	setDialogOpen(true)
	// }

	// const handleCloseDialog = () => {
	// 	setDialogOpen(false)
	// }

	// // code added by Basit Ali

	// const getFilteredByPlans = (selectedPlan) => {
	// 	const prevUsers = allUsers
	// 	const filterdUsers = prevUsers.filter((user) => {
	// 		let found = false
	// 		if (user.plans.findIndex((plan) => plan._id === selectedPlan) >= 0) {
	// 			found = true
	// 		}
	// 		if (found) {
	// 			return user
	// 		} else {
	// 			return null
	// 		}
	// 	})

	// 	return filterdUsers
	// }

	// const selectByPlansHandler = (e) => {
	// 	//alert(e.target.value);
	// 	//console.log(users);

	// 	if (e.target.value === 'all') {
	// 		setUsers(allUsers)
	// 		setUsersCount(allUsers.length)
	// 		setPlansSelctTheme({
	// 			bgColor: 'white',
	// 			color: 'black',
	// 		})
	// 		return
	// 	}

	// const prevUsers = allUsers;
	// const filterdUsers = prevUsers.filter((user) => {
	// 	let found = false;
	// 	if (user.plans.findIndex((plan) => plan._id === e.target.value) >= 0) {
	// 		found = true;
	// 	}
	// 	if (found) {
	// 		return user;
	// 	} else {
	// 		return null;
	// 	}
	// });

	// console.log(filterdUsers);
	// setUsers(filterdUsers);
	// setUsersCount(filterdUsers.length);

	// changing theme selection button accordingly

	// 		if (e.target.value === 'essential') {
	// 			setPlansSelctTheme({
	// 				bgColor: '#3791da',
	// 				color: 'white',
	// 			})

	// 			let prevPlans = selectedPlans
	// 			prevPlans = [...prevPlans, 'essential']

	// 			let filterdUsers = []
	// 			prevPlans.forEach((plan) => {
	// 				let filtered = getFilteredByPlans(plan)
	// 				let newFiltered = filterdUsers.concat(filtered)
	// 				filterdUsers = [...newFiltered]
	// 			})
	// 			setSelectedPlans((prev) => {
	// 				return [...prev, 'essential']
	// 			})

	// 			setUsers(filterdUsers)
	// 			setUsersCount(filterdUsers.length)
	// 		} else if (e.target.value === 'premium') {
	// 			setPlansSelctTheme({
	// 				bgColor: '#f640e9',
	// 				color: 'white',
	// 			})

	// 			let prevPlans = selectedPlans
	// 			prevPlans = [...prevPlans, 'premium']

	// 			let filterdUsers = []
	// 			prevPlans.forEach((plan) => {
	// 				let filtered = getFilteredByPlans(plan)

	// 				let newFiltered = filterdUsers.concat(filtered)
	// 				filterdUsers = [...newFiltered]
	// 			})
	// 			setSelectedPlans((prev) => {
	// 				return [...prev, 'premium']
	// 			})

	// 			setUsers(filterdUsers)
	// 			setUsersCount(filterdUsers)
	// 		} else if (e.target.value === 'free') {
	// 			setPlansSelctTheme({
	// 				bgColor: '#e0e0e0',
	// 				color: 'black',
	// 			})

	// 			let prevPlans = selectedPlans
	// 			prevPlans = [...prevPlans, 'free']

	// 			let filterdUsers = []
	// 			prevPlans.forEach((plan) => {
	// 				let filtered = getFilteredByPlans(plan)
	// 				filterdUsers.concat(filtered)
	// 			})
	// 			setSelectedPlans((prev) => {
	// 				return [...prev, 'free']
	// 			})

	// 			setUsers(filterdUsers)
	// 			setUsersCount(filterdUsers)
	// 		} else if (e.target.value === 'basic') {
	// 			setPlansSelctTheme({
	// 				bgColor: '#3791da',
	// 				color: 'white',
	// 			})

	// 			let prevPlans = selectedPlans
	// 			prevPlans = [...prevPlans, 'basic']

	// 			let filterdUsers = []
	// 			prevPlans.forEach((plan) => {
	// 				let filtered = getFilteredByPlans(plan)
	// 				filterdUsers.concat(filtered)
	// 			})
	// 			setSelectedPlans((prev) => {
	// 				return [...prev, 'basic']
	// 			})

	// 			setUsers(filterdUsers)
	// 			setUsersCount(filterdUsers)
	// 		} else {
	// 			setPlansSelctTheme({
	// 				bgColor: '##e0e0e0',
	// 				color: 'black',
	// 			})
	// 		}
	// 	}

	// const selectByYearOnly = (value) => {
	// 	const prevUsers = allUsers

	// 	const filteredUsers = prevUsers.filter((user) => {
	// 		const date = user.createdAt.split('T')[0]
	// 		const year = date.split('-')[0]
	// 		if (Number(year) === value.getFullYear()) {
	// 			return user
	// 		} else {
	// 			return null
	// 		}
	// 	})

	// 	setUsers(filteredUsers)
	// 	setUsersCount(filteredUsers.length)
	// 	// const month = value.getMonth();
	// 	// console.log(month);
	// 	setYear(value)
	// }
	// 	const selectByMonthAndYear = (value) => {
	// 		const month = value.getMonth()
	// 		const year = value.getFullYear()

	// 		const prevUsers = allUsers
	// 		const filteredUsers = prevUsers.filter((user) => {
	// 			const date = user.createdAt.split('T')[0]
	// 			const userYear = date.split('-')[0]
	// 			const userMonth = date.split('-')[1]

	// 			if (Number(userMonth) === month + 1 && Number(userYear) === year) {
	// 				return user
	// 			} else {
	// 				return null
	// 			}
	// 		})

	// 		setUsers(filteredUsers)
	// 		setUsersCount(filteredUsers.length)
	// 		setMonthYear(value)
	// 	}

	// 	// selectHandler function here

	// 	const selectHandler = (id, select) => {
	// 		const prevAllUsers = allUsers

	// 		let filteredUsers = []

	// 		let prevPlans = selectedPlans
	// 		let filtered = null

	// 		if (select === false) {
	// 			// updating state

	// 			filtered = prevPlans.filter((plan) => {
	// 				if (plan !== id) {
	// 					return plan
	// 				}
	// 				return null
	// 			})

	// 			if (filtered.length === 0) {
	// 				setUsers(prevAllUsers)
	// 				setUsersCount(prevAllUsers.length)
	// 				setSelectedPlans((prev) => {
	// 					return [...filtered]
	// 				})
	// 				return false
	// 			}

	// 			filteredUsers.length = 0
	// 			filtered.forEach((fp) => {
	// 				let foundUsers = getFilteredByPlans(fp)

	// 				foundUsers.map((u) => {
	// 					let newU = u
	// 					filteredUsers.push(newU)
	// 				})
	// 			})

	// 			console.log('filtered users', filteredUsers)
	// 			setUsersCount(filteredUsers.length)
	// 			setUsers(filteredUsers)
	// 			setSelectedPlans((prev) => {
	// 				return [...filtered]
	// 			})
	// 		} else {
	// 			prevPlans = [...prevPlans, id]

	// 			filteredUsers.length = 0
	// 			prevPlans.forEach((pp) => {
	// 				let foundUsers = getFilteredByPlans(pp)

	// 				foundUsers.map((u) => {
	// 					let newU = u
	// 					filteredUsers.push(newU)
	// 				})
	// 			})
	// 			setSelectedPlans((prev) => {
	// 				return [...prevPlans]
	// 			})
	// 			setUsersCount(filteredUsers.length)
	// 			setUsers(filteredUsers)
	// 		}
	// 	}

	// 	const ShyCell = (props) => (advanced ? <TableCell {...props}>{props.children}</TableCell> : null)

	// 	if (props.userList.loading === false && users !== undefined) {
	// 		const selectedUser = props.userList.users.find((user) => user._id === selectedUserId)
	// 		return (
	// 			<div className={classes.root}>
	// 				<Container
	// 					maxWidth={false}
	// 					className={classes.container}>
	// 					<div className={classes.top}>
	// 						<Typography
	// 							variant='h4'
	// 							className={classes.heading}>
	// 							<b>Admin</b>
	// 						</Typography>

	// 						<FormControlLabel
	// 							control={
	// 								<Switch
	// 									checked={advanced}
	// 									onChange={handleSwitchChange}
	// 									name='advanced'
	// 								/>
	// 							}
	// 							label='Advanced'
	// 						/>
	// 					</div>

	// 					{/*** **************************************************************** Code added By Basit Ali  *** **************************************************************** */}

	// 					{/*** **************************************************************** Code added By Basit Ali  *** **************************************************************** */}

	// 					<TableContainer
	// 						component={Paper}
	// 						className={classes.tableContainer}>
	// 						<Table
	// 							className={classes.table}
	// 							size='medium'
	// 							stickyHeader>
	// 							<EnhancedTableHead
	// 								order={order}
	// 								orderBy={orderBy}
	// 								onRequestSort={handleRequestSort}
	// 								advanced={advanced}
	// 							/>

	// 							<TableBody>
	// 								{users.map((user, index) => {
	// 									const progress = calcUserProgress(user)
	// 									return (
	// 										<TableRow
	// 											key={index}
	// 											hover
	// 											onClick={(event) => handleRowClick(event, user._id)}>
	// 											<TableCell
	// 												component='th'
	// 												scope='style'>
	// 												{user.firstName}
	// 											</TableCell>
	// 											<TableCell component='th'>{user.lastName}</TableCell>
	// 											<ShyCell>{user.admin ? '👑' : ''}</ShyCell>
	// 											<TableCell>{user.email}</TableCell>
	// 											<TableCell>{moment.utc(user.createdAt).format('MMM Do, YYYY')}</TableCell>
	// 											<TableCell>
	// 												{user.plans &&
	// 													user.plans.map((plan, index) => (
	// 														<Chip
	// 															key={index}
	// 															label={plan.name}
	// 															size='small'
	// 															color={
	// 																plan._id === 'free'
	// 																	? 'default'
	// 																	: plan._id === 'premium'
	// 																	? 'secondary'
	// 																	: 'primary'
	// 															}
	// 														/>
	// 													))}
	// 											</TableCell>
	// 											<ShyCell>{user.isVerified ? '✅' : '❌'}</ShyCell>
	// 											<TableCell>
	// 												{progress >= 100 ? (
	// 													'✅'
	// 												) : (
	// 													<LinearProgress
	// 														variant='determinate'
	// 														value={progress}
	// 													/>
	// 												)}
	// 											</TableCell>
	// 											<TableCell>
	// 												<Button
	// 													variant={progress >= 100 ? 'contained' : 'outlined'}
	// 													color='primary'
	// 													size='small'
	// 													component={Link}
	// 													to={`/results/${user._id}`}>
	// 													Results
	// 												</Button>
	// 											</TableCell>
	// 											<TableCell>
	// 												{user.leanAssessmentData.find((assessment) => assessment.id === 'interview')
	// 													?.completed && (
	// 													<Button
	// 														size='small'
	// 														variant='outlined'
	// 														component={Link}
	// 														to={`/interview/${user._id}`}>
	// 														Interview
	// 													</Button>
	// 												)}
	// 											</TableCell>
	// 											<ShyCell>{checkForMbResults(user)}</ShyCell>
	// 										</TableRow>
	// 									)
	// 								})}
	// 							</TableBody>
	// 						</Table>
	// 					</TableContainer>
	// 				</Container>

	// 				{selectedUser && (
	// 					<DetailView
	// 						open={dialogOpen}
	// 						user={selectedUser}
	// 						handleCloseDialog={handleCloseDialog}
	// 					/>
	// 				)}
	// 			</div>
	// 		)
	// 	} else {
	// 		return (
	// 			<Centered>
	// 				<CircularProgress />
	// 			</Centered>
	// 		)
	// 	}
	// }

	// const mapDispatchToProps = {
	// 	getAllUsers,

	const useStyles = makeStyles({
		root: {
			flex: '1 1 auto',
			display: 'flex',
			flexDirection: 'column',
			// overflow: 'hidden',
			// backgroundColor: 'rgb(251, 186, 186)'
		},
		container: {
			dislay: 'flex',
			flex: '0 1 auto',
		},
		top: {
			display: 'flex',
			flex: '0 1 auto',
			justifyContent: 'space-between',
			alignItems: 'center',
		},
		heading: {
			marginTop: '20px',
			marginBottom: '10px',
		},
		tableContainer: {
			display: 'flex',
			flex: '0 1 auto',
			// maxHeight: '100%'
		},
		table: {},
		bar: {
			height: '10px',
		},
		searchBarWrapper: {
			border: '1px solid lightgrey',
			borderRadius: '5px',
			display: 'flex',
			alignItems: 'center',
			padding: '0px 10px',
			width: 'fit-content',
			marginBottom: '10px',
			background: '#ffff',
		},
		searchBar: {
			outline: 'none',
			height: '40px',
			width: '200px',
			border: 'none',
			background: 'inherit',
		},
		searchIcon: {
			width: '10px',
			cursor: 'pointer',
		},
	})

	const classes = useStyles()
	const [dialogOpen, setDialogOpen] = useState(false)
	const [selectedUserId, setSelectedUserId] = useState(null)

	const [advanced, setAdvanced] = useState(false)
	const [order, setOrder] = useState('desc')
	const [orderBy, setOrderBy] = useState('createdAt')
	const [users, setUsers] = useState(props.userList.users)
	const [searchUser, setSearchUser] = useState('')

	// Load user data on page load (via api)
	useEffect(() => {
		if (isEmpty(props.userList)) {
			props.getAllUsers()
		} else if (props.userList.users) {
			setUsers(stableSort(props.userList.users, getComparator(order, orderBy)))
		}
	}, [props.userList])

	const handleRequestSort = (event, property) => {
		// console.log('SORT', event, property)
		const isAsc = orderBy === property && order === 'asc'
		const newOrder = isAsc ? 'desc' : 'asc'
		let newOrderBy = property
		console.log('sort property:', newOrderBy)
		console.log('sort direction:', newOrder)
		console.log(' ')
		setOrder(newOrder)
		setOrderBy(newOrderBy)
		let specialSort = false

		if (property === 'plans' || property === 'assessmentsComplete') {
			specialSort = property
		}
		setUsers(stableSort(users, getComparator(newOrder, newOrderBy, specialSort)))
	}

	const handleSwitchChange = (event) => {
		setAdvanced(event.target.checked)
	}

	const checkForMbResults = (user) => {
		if (user.plans[0].assessments.includes('myers-briggs')) {
			if (user.leanAssessmentData) {
				const data = user.leanAssessmentData.find((assessment) => assessment.id === 'myers-briggs')
				if (data && data.completed) {
					if (data && data['MBTI'] === true && data['SII'] === true) {
						return '✅'
					} else {
						return '❌'
					}
				} else {
					return ''
				}
			}
		} else {
			return ''
		}
	}

	const calcUserProgress = (user) => {
		let assessmentsComplete = 0
		if (user.leanAssessmentData) {
			user.leanAssessmentData.forEach((assessment) => {
				if (assessment.completed) {
					assessmentsComplete += 1
				}
			})
		}
		const totalAssessments = user.plans[0].assessments.length
		return (assessmentsComplete / totalAssessments) * 100
	}

	const handleRowClick = (event, userId) => {
		// console.log('row clicked!', userId, 'event:', event)
		setSelectedUserId(userId)
		handleOpenDialog()
	}

	const handleOpenDialog = () => {
		setDialogOpen(true)
	}

	const handleCloseDialog = () => {
		setDialogOpen(false)
	}

	const onSearch = async (e) => {
		e.preventDefault()
		if (!searchUser) {
			await props.getAllUsers()
		} else {
			await props.getFilteredUsers(searchUser)
		}
	}

	const ShyCell = (props) => (advanced ? <TableCell {...props}>{props.children}</TableCell> : null)

	if (props.userList.loading === false && users !== undefined) {
		const selectedUser = props.userList.users.find((user) => user._id === selectedUserId)
		return (
			<div className={classes.root}>
				<Container
					maxWidth={false}
					className={classes.container}>
					<div className={classes.top}>
						<Typography
							variant='h4'
							className={classes.heading}>
							<b>Admin</b>
						</Typography>

						<FormControlLabel
							control={
								<Switch
									checked={advanced}
									onChange={handleSwitchChange}
									name='advanced'
								/>
							}
							label='Advanced'
						/>
					</div>

					<div className={classes.filterWrapper}>
						<form
							onSubmit={onSearch}
							className={classes.searchBarWrapper}>
							<input
								className={classes.searchBar}
								placeholder='Search...'
								value={searchUser}
								onChange={async (e) => {
									setSearchUser(e.target.value)
									if (e.target.value.length < 1) {
										await props.getAllUsers()
									}
								}}
							/>
							<SearchIcon
								className={classes.searchIcon}
								onClick={onSearch}
							/>
						</form>
					</div>

					<TableContainer
						component={Paper}
						className={classes.tableContainer}>
						<Table
							className={classes.table}
							size='medium'
							stickyHeader>
							<EnhancedTableHead
								order={order}
								orderBy={orderBy}
								onRequestSort={handleRequestSort}
								advanced={advanced}
							/>

							<TableBody>
								{users.map((user, index) => {
									const progress = calcUserProgress(user)
									return (
										<TableRow
											key={index}
											hover
											onClick={(event) => handleRowClick(event, user._id)}>
											<TableCell
												component='th'
												scope='style'>
												{user.firstName}
											</TableCell>
											<TableCell component='th'>{user.lastName}</TableCell>
											<ShyCell>{user.admin ? '👑' : ''}</ShyCell>
											<TableCell>{user.email}</TableCell>
											<TableCell>{moment.utc(user.createdAt).format('MMM Do, YYYY')}</TableCell>
											<TableCell>
												{user.plans &&
													user.plans.map((plan, index) => (
														<Chip
															key={index}
															label={plan.name}
															size='small'
															color={
																plan._id === 'free'
																	? 'default'
																	: plan._id === 'premium'
																	? 'secondary'
																	: 'primary'
															}
														/>
													))}
											</TableCell>
											<ShyCell>{user.isVerified ? '✅' : '❌'}</ShyCell>
											<TableCell>
												{progress >= 100 ? (
													'✅'
												) : (
													<LinearProgress
														variant='determinate'
														value={progress}
													/>
												)}
											</TableCell>
											<TableCell>
												<Button
													variant={progress >= 100 ? 'contained' : 'outlined'}
													color='primary'
													size='small'
													component={Link}
													to={`/results/${user._id}`}>
													Results
												</Button>
											</TableCell>
											<TableCell>
												{user.leanAssessmentData.find((assessment) => assessment.id === 'interview')
													?.completed && (
													<Button
														size='small'
														variant='outlined'
														component={Link}
														to={`/interview/${user._id}`}>
														Interview
													</Button>
												)}
											</TableCell>
											<ShyCell>{checkForMbResults(user)}</ShyCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
					</TableContainer>
				</Container>

				{selectedUser && (
					<DetailView
						open={dialogOpen}
						user={selectedUser}
						handleCloseDialog={handleCloseDialog}
					/>
				)}
			</div>
		)
	} else {
		return (
			<Centered>
				<CircularProgress />
			</Centered>
		)
	}
}

const mapDispatchToProps = {
	getAllUsers,
	getFilteredUsers,
}

const mapStateToProps = (state) => {
	return {
		userList: state.admin.userList,
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
