import React, { useEffect, useState } from "react";

// My Components
import DepressionResults from "../results/DepressionResults";
import NeoResults from "../results/NeoResults";
import MbtiResults from "../results/MbtiResults";
import SiiResults from "../results/SiiResults";
import CareerValueResults from "../results/CareerValueResults";
import Centered from "../layout/Centered";
import SidebarMenu from "../results/SidebarMenu";
import isEmpty from "is-empty";
import HappyBox from "../layout/HappyBox";

import { makeStyles } from "@mui/styles";

// MUI Imports
import {
  Typography,
  Container,
  CircularProgress,
  Paper,
} from "@mui/material";

const useStyles = makeStyles({
  root: {
    display: "flex",
  },
  content: {
    marginTop: "30px",
    marginBottom: "30px",
  },
});

const Results = (props) => {
  const classes = useStyles();

  const [view, setView] = useState(null); // stores current result view
  const [scrollTo, setScrollTo] = useState(null); // retains scroll position while sii is loading

  const { results, assessments, careerValueAnswers } = props;
  console.log(props);

  useEffect(() => {
    // wait till sii view is loaded to animate scroll to selected section
    if (view === "sii" && scrollTo) {
      const element = document.getElementById(scrollTo);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", alignToTop: true });
      }
    }
  }, [view, scrollTo]);

  const handleNavSelect = (e) => {
    const id = e.currentTarget.id;
    setView(id);
    if (view !== "sii") {
      setScrollTo(null); // reset scrollTo if not sii
    }
  };

  const handleScrollTo = (e) => {
    const data = e.currentTarget.getAttribute("data");
    if (view !== "sii") {
      // if view isn't 'sii' set to sii and store desired scroll target
      // this will trigger a re-render and useEffect will run and handle scrolling based on stored scrollTo value
      setView("sii");
      setScrollTo(data);
    } else {
      // if view is 'sii' scroll to selected section
      const element = document.getElementById(data);
      if (element) {
        element.scrollIntoView({ behavior: "smooth", alignToTop: true });
      }
    }
  };

  const ResultsView = (props) => {
    const { view } = props;

    const mbtiResults = results.byId["myers-briggs"];
    const depressionResults = results.byId["depression-screening"];
    const neoResults = results.byId["neo"];
    const siiResults = results.byId["myers-briggs"];

    switch (view) {
      case "depression-screening":
        return <DepressionResults results={depressionResults} />;

      case "neo":
        return <NeoResults results={neoResults} />;

      case "mbti":
        return <MbtiResults results={mbtiResults} />;

      case "sii":
        return <SiiResults results={siiResults} />;

      case "career-values":
        return <CareerValueResults results={careerValueAnswers} />;

      default:
        return (
          <HappyBox>
            <Typography variant="h6" gutterBottom>
              You Did it!
            </Typography>
            <Typography gutterBottom>
              Woohoo! You made it through the entire data input phase! 🙌. Now
              its time to check out all those beautiful data driven results!
            </Typography>
            <Typography gutterBottom>
              Your results are broken up by assessment in the menu to the left.
              We are constantly working hard to improve this section and make
              your results easier to interpret and understand. You can always
              upgrade to our Essential or Premium plans to go over these results
              with a PHD level Psychologist.
            </Typography>
          </HappyBox>
        );
    }
  };

  // if user is loaded
  if (!isEmpty(props.assessments)) {
    return (
      <div className={classes.root}>
        <SidebarMenu
          view={view}
          assessments={assessments}
          handleNavSelect={handleNavSelect}
          handleScrollTo={handleScrollTo}
          elevateResults={{
            mbti: results.byId?.["myers-briggs"]?.MBTI ? true : false,
            sii: results.byId?.["myers-briggs"]?.SII ? true : false,
          }}
        />
        {/* Content */}
        <Container maxWidth="lg" className={classes.content}>
          <ResultsView view={view} />
        </Container>
      </div>
    );
  } else {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    );
  }
};

export default Results;
