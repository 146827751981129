import React, { useEffect } from "react";
import { Link, withRouter, useRouteMatch } from "react-router-dom";
import { connect } from "react-redux";
import isEmpty from "is-empty";

// Images
import logo from "../../images/logo_all_white.png";

// MUI Imports
import { makeStyles } from "@mui/styles";

import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// MUI Icons
import MenuIcon from "@mui/icons-material/Menu";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import StarIcon from "@mui/icons-material/Star";
import ResultsIcon from "@mui/icons-material/InsertChart";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

// Actions
import { logoutUser, fetchUserData } from "../../actions/index";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#fff",
  },
  logo: {
    height: "70px",
    padding: "5px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  hamburgerMenu: {
    //color: "#242424",
  },
  floatingMenu: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    position: "fixed",
    top: 0,
    right: 0,
    padding: "10px",
    borderBottomLeftRadius: "25px",
    // zIndex: 1
  },
});

const Navbar = (props) => {
  const classes = useStyles();

  const routeMatch = useRouteMatch(["/results/:userId", "/test", "/results"]);
  const hideNavbar = routeMatch ? routeMatch.isExact : false;
  // console.log('match', routeMatch)

  // Load user data on page load (via api)
  useEffect(() => {
    if (isEmpty(props.user) && props.auth.isAuthenticated) {
      props.fetchUserData(); // fetch user data via api and store in redux
    }
  }, [props.auth.isAuthenticated]);

  const MenuHamburger = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handleLogout = (e) => {
      handleMenuClose();
      props.logoutUser();
      props.history.push("/");
    };

    return (
      <>
        <IconButton color="inherit" aria-label="menu" onClick={handleMenuClick}>
          <MenuIcon fontSize="large" className={classes.hamburgerMenu} />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {/* Dont show to premium plan members */}
          {props.user.plans && props.user.plans[0]._id !== "premium" && (
            <MenuItem component={Link} to="/plans" onClick={handleMenuClose}>
              <ListItemIcon>
                <LocalOfferIcon />
              </ListItemIcon>
              <ListItemText>Upgrade Plan</ListItemText>
            </MenuItem>
          )}

          <MenuItem component={Link} to="/results" onClick={handleMenuClose}>
            <ListItemIcon>
              <ResultsIcon />
            </ListItemIcon>
            <ListItemText>Results</ListItemText>
          </MenuItem>

          {props.user.admin && (
            <MenuItem component={Link} to="/admin" onClick={handleMenuClose}>
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText>Admin</ListItemText>
            </MenuItem>
          )}

          {props.user.admin && (
            <MenuItem
              component={Link}
              to="/admin/search-record"
              onClick={handleMenuClose}
            >
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText>Interests</ListItemText>
            </MenuItem>
          )}

          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        </Menu>
      </>
    );
  };

  if (props.auth.isAuthenticated) {
    // only render navbar if user is authenticated
    if (!hideNavbar) {
      return (
        <AppBar position="static" className={classes.root}>
          <Toolbar className={classes.toolbar}>
            <Link to="/">
              <img src={logo} className={classes.logo} alt="Logo" />
            </Link>

            {/* Display login our logout button based on user logged in status */}
            {props.auth.isAuthenticated ? (
              <MenuHamburger />
            ) : (
              <Button color="inherit" component={Link} to="/login">
                Login
              </Button>
            )}
          </Toolbar>
        </AppBar>
      );
    } else {
      // minimal menu goes here (for results, etc)
      return null;
      // <div className={classes.floatingMenu}>
      //   <MenuHamburger />
      // </div>
    }
  } else {
    return null;
  }
};

const mapDispatchToProps = {
  logoutUser,
  fetchUserData,
};

const mapStateToProps = (state) => {
  return {
    auth: state.auth,
    user: state.user,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Navbar));
