import React from "react";
import { makeStyles } from "@mui/styles";
import { useQuery } from "@apollo/client";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// MUI Imports
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  IconButton,
  Box,
  Chip,
  CircularProgress,
} from "@mui/material";

// My Imports
import SdCurve from "../widgits/SdCurve";
import Centered from "../layout/Centered";
import CustomTooltip from "../layout/CustomTooltip";
import HappyBox from "../layout/HappyBox";

// GQL Query Imports
import { GET_NEO_RESULTS } from "./constants/queries";

// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import neoDisplayNames from "../../constants/neo-display-names.json";

const useStyles = makeStyles({
  root: {
    padding: "20px",
    marginTop: "20px",
    marginBottom: "30px",
  },
  bar: {
    height: "10px",
  },
  category: {
    fontSize: "1.4em",
    fontWeight: "400",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  mainRow: {
    "& > *": {
      borderBottom: "unset",
    },
    cursor: "pointer",
    userSelect: "none",
  },
  subRow: {
    "&:last-child": {
      "& td, & th": {
        borderBottom: "none",
      },
      // backgroundColor: 'red',
    },
  },
  subcategoriesHeading: {
    fontSize: "1.2em",
  },
  innerTable: {
    backgroundColor: "#f7f7f7",
    // borderTop: '1px solid #e0e0e0',
    // padding: '0px',
    marginBottom: "30px",
  },
  disableHover: {
    pointerEvents: "none",
  },
  highlight: {
    backgroundColor: "#40a5f6",
    transition: "background-color 5s ease-in-out",
    animation: "$blinking 1s infinite",
    "&:hover": {
      animation: "none",
      backgroundColor: "#40a5f6",
    },
    animationDelay: "0s", // Adjust this value for each button
  },
  "@keyframes blinking": {
    "0%": {
      backgroundColor: " #8dc4f9", // Light blue
    },
    "50%": {
      backgroundColor: "#40a5f6", // Dark blue
    },
    "100%": {
      backgroundColor: " #8dc4f9", // Light blue
    },
  },
});

const headCells = [
  { id: "name", numeric: false, disablePadding: false, label: "" },
  { id: "rawScore", numeric: true, disablePadding: false, label: "Raw Score" },
  { id: "tScore", numeric: true, disablePadding: false, label: "T-Score" },
  {
    id: "tScore",
    numeric: false,
    disablePadding: false,
    label: "",
    width: "25%",
  },
  { id: "result", numeric: false, disablePadding: false, label: "" },
  { id: "expand", numeric: false, disablePadding: false, label: "" },
];

const NeoResults = (props) => {
  const classes = useStyles();
  const { results } = props;

  // Load Contentful Data
  const { loading, error, data } = useQuery(GET_NEO_RESULTS);
  // console.log('loading:', loading, 'data:', data)

  if (loading) {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    );
  } else {
    const assessment = data.neoResults.assessment;
    // const analysis = data.neoResults.details.items[0][results.depression.toLowerCase()]
    const interpretation = data.neoResults.interpretation.json;
    const disclaimer = data.neoResults.disclaimer.json;
    const facets = data.neoResults.details.neoFacets;
    return (
      <>
        {/* Top Text Zone */}
        <HappyBox>
          <Typography variant="h4" gutterBottom>
            NEO Personality Inventory
          </Typography>
          <Typography gutterBottom component="div">
            {documentToReactComponents(assessment.longDescription.json)}
          </Typography>
        </HappyBox>

        <HappyBox padding={false}>
          <Typography variant="h4" style={{ padding: "20px" }}>
            Your Results
          </Typography>

          {/* Table Zone */}
          <TableContainer>
            <Table className={classes.table} stickyHeader>
              <TableHead>
                <TableRow>
                  {headCells.map((headCell, index) => (
                    <TableCell
                      key={index}
                      align={headCell.numeric ? "right" : "left"}
                      padding={headCell.disablePadding ? "none" : "normal"}
                      style={headCell.width ? { width: headCell.width } : {}}
                    >
                      {headCell.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {Object.keys(results).map((categoryName, index) => {
                  const category = results[categoryName]; // category data
                  const name = neoDisplayNames[categoryName].name; // category display name
                  return (
                    <Row
                      key={index}
                      name={name}
                      id={categoryName}
                      category={category}
                      facets={facets}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </HappyBox>

        <HappyBox>
          <Typography variant="h4" gutterBottom>
            What does it mean?
          </Typography>
          <Typography gutterBottom component="div">
            {documentToReactComponents(interpretation)}
          </Typography>
        </HappyBox>

        <HappyBox>
          <Typography variant="h4" gutterBottom>
            Disclaimer
          </Typography>
          <Typography gutterBottom component="div">
            {documentToReactComponents(disclaimer)}
          </Typography>
        </HappyBox>
      </>
    );
  }
};

const Row = (props) => {
  const classes = useStyles();
  const { id, name, category } = props;
  // name: category display name
  // id: categoryId
  // category: category lookup table

  const [open, setOpen] = React.useState(false);
  const [expandedRowIndex, setExpandedRowIndex] = React.useState(null);
  const [highlightedIndexes, setHighlightedIndexes] = React.useState([]);

  const handleSubRowClick = (index) => {
    setExpandedRowIndex(index === expandedRowIndex ? null : index);
  };

  const handleButtonClick = (index) => {
    setHighlightedIndexes(prevIndexes => [...prevIndexes, index]);
  };

  return (
    <>
      {/* MAIN CATEGORIES */}
      <TableRow className={classes.mainRow} onClick={() => setOpen(!open)}>
        <TableCell component="th" scope="style">
          <Typography variant="h6" className={classes.category}>
            {name}
          </Typography>
        </TableCell>
        <TableCell align="right">{category.rawScore}</TableCell>
        <TableCell align="right">{category.tScore}</TableCell>
        <TableCell>
          <SdCurve tScore={category.tScore} id={id} />
        </TableCell>
        <TableCell align="right">
          <Chip
            size="small"
            label={
              category.tScore > 60
                ? "High"
                : category.tScore < 40
                ? "Low"
                : "Average"
            }
          />
        </TableCell>
        <TableCell align="right">
          <IconButton aria-label="expand row" size="small">
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      {/* SUBCATEGORIES */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={2} className={classes.innerTable}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Subcategories</TableCell>
                    <TableCell align="right">Raw Score</TableCell>
                    <TableCell align="right">T-Score</TableCell>
                    <TableCell align="right">Graph</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(category.subcategories).map(
                    (subcategoryName, index) => {
                      const subcategory =
                        category.subcategories[subcategoryName];
                      const displayName =
                        neoDisplayNames[id].subcategories[subcategoryName];
                      const label =
                        subcategory.tScore > 60
                          ? "High"
                          : subcategory.tScore < 40
                          ? "Low"
                          : "Average";
                      const facet = props.facets.find(
                        (facet) => facet.id === subcategoryName
                      );
                      // const showTooltip = label === 'Average' ? false : undefined
                      const interpretation = (label) => {
                        switch (label) {
                          case "High":
                            return facet.high;
                          case "Low":
                            return facet.low;
                          default:
                            return "";
                        }
                      };

                      return (
                        <>
                          <React.Fragment key={index}>
                            <TableRow
                              key={index}
                              className={classes.subRow}
                              hover
                              onClick={() => handleSubRowClick(index)}
                            >
                              <TableCell component="th" scope="style">
                                {displayName}
                              </TableCell>
                              <TableCell align="right">
                                {subcategory.rawScore}
                              </TableCell>
                              <TableCell align="right">
                                {subcategory.tScore}
                              </TableCell>
                              <TableCell>
                                <SdCurve
                                  tScore={subcategory.tScore}
                                  id={subcategoryName}
                                />
                              </TableCell>
                              <TableCell align="right">
                                <CustomTooltip
                                  placement="left"
                                  arrow
                                  title={interpretation(label)}
                                >
                                  <Chip
                                    className={classes.disableHover}
                                    size="small"
                                    label={label}
                                  />
                                </CustomTooltip>
                              </TableCell>
                              <TableCell align="right">
                                <IconButton
                                  className={
                                    `${!interpretation(label) ? classes.disableHover : ""} ${interpretation(label) && !highlightedIndexes.includes(index)
                                      ? classes.highlight
                                      : ""}`
                                  }
                                  aria-label="expand row"
                                  size="small"
                                  onClick={() => handleButtonClick(index)} // attach the click event handler
                                >
                                  {expandedRowIndex === index && interpretation(label) ? (
                                    <KeyboardArrowUpIcon />
                                  ) : (
                                    <KeyboardArrowDownIcon />
                                  )}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                            {expandedRowIndex === index &&
                              interpretation(label) && (
                                <TableRow>
                                  <TableCell colSpan={6}>
                                    <div>{interpretation(label)}</div>
                                  </TableCell>
                                </TableRow>
                              )}
                          </React.Fragment>
                        </>
                      );
                    }
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default NeoResults;
