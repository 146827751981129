import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
function HoverDetails({ description }) {
  return (
    <>
      <Tooltip title={description} placement="top-start">
        <IconButton>
          <HelpIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}

export default HoverDetails;
