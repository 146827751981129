import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import isEmpty from "is-empty";

// My Components
import FullPageBkg from "../layout/FullPageBkg";

// MUI Imports
import { makeStyles } from '@mui/styles';
import { TextField, Button, Typography, CircularProgress } from '@mui/material';

import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

// Actions
import { loginUser } from "../../actions/index";

const useStyles = makeStyles({
  root: {
    fontFamily: [
      '"Bebas Neue"',
      'sans-serif',
    ].join(','),
  },
  subtitle: {
    color: 'rgb(119, 119, 119)'
  },
  form: {
    '& .MuiTextField-root': {
      marginTop: '10px'
    }
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
  },
  spinner: {
    color: 'rgba(255, 255, 255, 0.5)'
  }
});

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [spinner, setSpinner] = useState(false);

  const { isAuthenticated } = props.auth
  const errors = props.errors

  // If logged in and user navigates to Login page, should redirect them to dashboard
  useEffect(() => {
    if (isAuthenticated) props.history.push("/");
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isEmpty(errors)) setSpinner(false)
  }, [errors]);

  const handleEmailChange = e => {
    setEmail(e.target.value)
  };

  const handlePasswordChange = e => {
    setPassword(e.target.value)
  };

  const handleSubmit = e => {
    e.preventDefault();

    const userData = {
      email,
      password,
    };

    if (isEmpty(errors)) setSpinner(true)
    props.loginUser(userData);
  };

  const classes = useStyles();

  return(
    <FullPageBkg>
      <Typography variant="loginH2" gutterBottom> Log In </Typography>

      <Typography variant="body1">
        Don't have an account? 
        <Button component={Link} to="/register" color="orange" variant="text">Sign Up</Button>
      </Typography>

      <Typography variant='body1'>
        Forget your password?
        <Button component={Link} to="/forgot" color="orange" variant="text">Reset</Button>
      </Typography>
      <br></br>
      <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField id="email" label="Email" fullWidth variant="standard"
          onChange={handleEmailChange}
          value={email}
          error={errors.email || errors.emailnotfound ? true : false}
          helperText={errors.email || errors.emailnotfound}
          type="email"
        />
        <TextField id="password" label="Password" fullWidth variant="standard"
          onChange={handlePasswordChange}
          value={password}
          error={errors.password || errors.passwordincorrect ? true : false}
          helperText={errors.password || errors.passwordincorrect}
          type="password"
        />
        <div className={classes.buttonBox}>
          <Button component={Link} to="/" color="orange" sx={{fontFamily: 'Bebas Neue', fontSize: '1.2rem'}}>
            <ArrowBackIos/>Back
          </Button>
          <Button type="submit" variant="contained" color="purple" sx={{width: '30%'}}>
            { spinner ?
              <CircularProgress size={25} className={classes.spinner} /> :
              'Log In'
            }
          </Button>
        </div>
      </form>
    </FullPageBkg>
  )
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  loginUser
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
