import React from 'react';
import { makeStyles } from "@mui/styles";
import { globalStyles } from './constants/globalStyles';

// MUI Imports
import {
  Container, Stack, Typography
} from '@mui/material';
import Avatar from '@mui/material/Avatar';

import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

import eliPhoto from "../../images/client_portraits/Eli.JPG";
import lizziePhoto from "../../images/client_portraits/Lizzie.jpg";
import khaliaPhoto from "../../images/client_portraits/Khalia.jpg";

const useGlobalStyles = makeStyles({
  ...globalStyles
});

const useStyles = makeStyles({
  root: {
    padding: '80px 0',
    minHeight: '500px',
    backgroundColor: '#F3F5F5',
    alignContent: 'center'
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    '@media (max-width: 780px)' : {
      flexDirection: 'column'
    }
  },
  item: {
    display: 'flex',
    flex: '1 1 33%',
    flexDirection: 'column',
    fontFamily: 'Roboto',
    justifyContent: 'space-between',
    margin: '5px',
    padding: '30px',
    maxWidth: '20%',
    boxShadow: '0px 0px 30px -13px #121212',
    transition: 'background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s',
    borderRadius: '10px 10px 10px 65px',
    margin: '20px 20px 20px 20px',
    '& svg': {
      fontSize: '1.8em',
      alignContent: 'right',
      color: '#9A512F'
    },
    '& .quote': {
      fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),
      fontSize: '.9em',
      lineHeight: '2em',
      minHeight: '250px'
    },
    '& .quoteAuthor': {
      fontFamily: ['"Montserrat"', 'sans-serif'].join(','),
      padding: '0px 20px',
      fontSize: '.9em',
    },
    '& .quoteCity': {
      fontFamily: ['"Source Sans Pro"', 'sans-serif'].join(','),
      fontSize: '.9em',
      padding: '0px 20px',
    },
    '& .quoteIcon': {
      alignSelf: 'flex-end'
    },
    '@media (max-width: 780px)' : {
      maxWidth: '100%',
    }
  },
  avatar: {
    display: 'flex',
    justifyContent: 'left',
  },
  name: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
    paddingBottom: '10px'
  }
});

const Testimonials = (props) => {
  const globalClasses = useGlobalStyles();
  const classes = useStyles();

  const { data } = props
  const testimonials = data.contentCollection.items

  return(
    <div className={`${classes.root}`}>
      <Typography variant='h2' align='center' gutterBottom sx={{fontWeight: '700', fontSize: '2.35em'}}>
        Our Happy Clients!
      </Typography>

      <div className={classes.container}>
        <div className={classes.item}>
          <Typography align='left' color="textSecondary" className="quote">
            My Career Assessment was so accurate it was scary and brought forward so many truths I had been previously unaware of, with lots of 'aha' moments. Now I am laser-focused on more appropriate career expectations.
          </Typography>
          <div className={classes.avatar}>
            <Avatar src={`${khaliaPhoto}`} sx={{ width: '80px', height: '80px', border: '3px solid #9A512F' }} />
            <div className={classes.name}>
              <Typography align='left' className='quoteAuthor'>Khalia</Typography>
              <Typography align='left' className='quoteCity'>New York, NY / Entrepreneur </Typography>
            </div>
          </div>
          <div className='quoteIcon'> <FormatQuoteIcon alignContent='right'></FormatQuoteIcon> </div>
        </div>
        <div className={classes.item}>
          <Typography align='left' color="textSecondary" className="quote">
            The Career Assessment told me about my strengths, and what I would be good at, and gave me the courage to finally start working on a career search and arduous interviewing process with the right mindset.
          </Typography>
          <div className={classes.avatar}>
            <Avatar src={`${eliPhoto}`} sx={{ width: '80px', height: '80px', border: '3px solid #9A512F' }} />
            <div className={classes.name}>
              <Typography align='left' className='quoteAuthor'>Eli</Typography>
              <Typography align='left' className='quoteCity'>New York, NY / Tech Start-ups & Commercial Real Estate</Typography>
            </div>
          </div>
          <div className='quoteIcon'> <FormatQuoteIcon alignContent='right'></FormatQuoteIcon> </div>
        </div>
        <div className={classes.item}>
          <Typography align='left' color="textSecondary" className="quote">
            The Career Assessment changed my life! It was so in-depth, it even brought to light how my childhood experiences might have influenced my aptitudes and it helped me to make a massive career change! Even if you are not looking for a big career change, it's a fascinating experience which I think could help anyone to become a happier person.
          </Typography>
          <div className={classes.avatar}>
            <Avatar src={`${lizziePhoto}`} sx={{ width: '80px', height: '80px', border: '3px solid #9A512F' }} />
            <div className={classes.name}>
              <Typography align='left' className='quoteAuthor'>Lizzie</Typography>
              <Typography align='left' className='quoteCity'>New York, NY / Sales → Graphic design</Typography>
            </div>
          </div>
          <div className='quoteIcon'> <FormatQuoteIcon alignContent='right'></FormatQuoteIcon> </div>
        </div>
      </div>



{/*
      <div className={classes.container}>
        {testimonials.map((item, index) => (
          <div className={classes.item} key={index}>
            <Typography align='left' color="textSecondary" className="quote">
              {item.quote}
            </Typography>
            <div className={classes.avatar}>
              <Avatar alt={item.name} src={`${item.image.url}`} sx={{ width: '80px', height: 'auto', border: '3px solid #9A512F' }} />
              <div className={classes.name}>
                <Typography align='left' className='quoteAuthor'>{item.name}</Typography>
                <Typography align='left' className='quoteCity'>{item.city}</Typography>
              </div>
            </div>
            <div className='quoteIcon'> <FormatQuoteIcon alignContent='right'></FormatQuoteIcon> </div>
          </div>
        ))}
      </div>
*/}
    </div>
  )
}

export default Testimonials;
