// import React, { Component } from "react";
import React, { useState, useEffect } from "react";
import { Link, withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Mixpanel } from "../../utils/mixpanel";

// My Components
import FullPageBkg from "../layout/FullPageBkg";

// MUI Imports
import { makeStyles } from '@mui/styles';
import {
  TextField, Button, Typography
} from '@mui/material';

import ArrowBackIos from '@mui/icons-material/ArrowBackIos';

// Actions
import { registerUser } from "../../actions/index";

const useStyles = makeStyles({
  root: {},
  container: {
    maxWidth: '400px'
  },
  form: {
    '& .MuiTextField-root': {
      marginTop: '10px',
    }
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
  }
});

const Register = (props) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const { planName, planId, price } = props.location.state || false

  const { isAuthenticated, userId } = props.auth
  const errors = props.errors

  // If logged in and user navigates to signup page, should redirect them to choose plan
  useEffect(() => {
    if (isAuthenticated) {

      Mixpanel.identify(userId)
      Mixpanel.people.set_once({
        $first_name: firstName,
        $last_name: lastName,
        $email: email,
        $created: new Date().toISOString()
      })

      if (planId) {
        if (planId === 'free') {
          console.log('free plan - redirect home')
          props.history.push("/");
        } else {
          console.log('plan chosen - redirect to checkout')
          props.history.push("/checkout", {
            planName: planName,
            planId: planId,
            price: price
          });
        }
      } else {
        console.log('no plan specified - redirect to choose plan')
        props.history.push("/plans");
      }
    }
  }, [isAuthenticated]);

  const handleSubmit = e => {
    e.preventDefault();

    const newUser = {
      firstName,
      lastName,
      email,
      password,
      password2
    };

    props.registerUser(newUser); // we could get logged in  user details here
  };

  const classes = useStyles();


  return(
    <FullPageBkg>
      <div className={classes.container}>
        <Typography variant="loginH2" gutterBottom>
          Sign Up
        </Typography>

        <Typography variant="body1">
          Already have an account?
          <Button component={Link} to="/login" color="orange" variant="text">Log in</Button>
        </Typography>

        <form className={classes.form} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <TextField id="firstName" label="First Name" fullWidth
            onChange={e => setFirstName(e.target.value)}
            value={firstName}
            error={errors.firstName ? true : false}
            helperText={errors.firstName}
            type="text"
          />
          <TextField id="lastName" label="Last Name" fullWidth
            onChange={e => setLastName(e.target.value)}
            value={lastName}
            error={errors.lastName ? true : false}
            helperText={errors.lastName}
            type="text"
          />
          <TextField id="email" label="Email" fullWidth
            onChange={e => setEmail(e.target.value)}
            value={email}
            error={errors.email ? true : false}
            helperText={errors.email}
            type="email"
          />
          <TextField id="password" label="Password" fullWidth
            onChange={e => setPassword(e.target.value)}
            value={password}
            error={errors.password ? true : false}
            helperText={errors.password}
            type="password"
          />
          <TextField id="password2" label="Confirm Password" fullWidth
            onChange={e => setPassword2(e.target.value)}
            value={password2}
            error={errors.password2 ? true : false}
            helperText={errors.password2}
            type="password"
          />

          <div className={classes.buttonBox}>
            <Button component={Link} to="/login" color="orange" sx={{fontFamily: 'Bebas Neue', fontSize: '1.2rem'}}>
              <ArrowBackIos/>Back
            </Button>
            <Button type="submit" variant="contained" color="purple">
              Sign Up
            </Button>
          </div>
        </form>
      </div>
    </FullPageBkg>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapDispatchToProps = {
  registerUser
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Register));
