import React from 'react';
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";


// MUI Imports
import {
  Container, Typography, Button, List, ListItem, ListItemIcon, ListItemText
} from '@mui/material';

// Icons
import LabelIcon from '@mui/icons-material/Label';
import CheckIcon from '@mui/icons-material/CheckCircle';

// My Imports
import PssVis from '../widgits/PssVis';
import Bar from '../widgits/Bar';
import BarDiv from '../widgits/BarDiv';
import BarHeader from '../widgits/BarHeader';

const MotionLabelIcon = motion(LabelIcon)

const useStyles = makeStyles({
  root: {},
  text: {

  },
  testDiv: {
    backgroundColor: 'red',
    height: '200px',
    width: '200px',
    padding: '20px'
  }
});

const Test = (props) => {
  const classes = useStyles();

  const testMotion = {
    hover: {
      color: "blue",
      x: 10
    }
  };

  return(
    <Container maxWidth='sm' className={classes.root}>
      <Typography variant="h1" component="h2" gutterBottom>
        h1. Heading
      </Typography>
      <PssVis value={40} />
      <PssVis value={20} />
      <PssVis value={35} />
      <BarHeader min={5} max={65} />
      <Bar value={5} height={2} min={5} max={65} />
      <Bar value={35} height={2} min={5} max={65} />
      <Bar value={60} height={2} min={5} max={65} />

      <BarDiv value={5} height={2} min={5} max={65} />
      <BarDiv value={35} height={2} min={5} max={65} />
      <BarDiv value={60} height={2} min={5} max={65} />


      <List>
        <ListItem button component={motion.div} whileHover='hover'>
          <ListItemIcon>
            <MotionLabelIcon variants={testMotion} />
          </ListItemIcon>
          <ListItemText
            primary='First List Item'
            secondary='Sup'
            className={classes.text}
          />
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <LabelIcon />
          </ListItemIcon>
          <ListItemText
            primary='Second List Item'
            className={classes.text}
          />
        </ListItem>
      </List>

    </Container>
  )
}

export default Test;
