import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import isEmpty from 'is-empty';
import { Mixpanel } from '../../utils/mixpanel';
import { useDispatch } from 'react-redux';

// MUI Imports
import { makeStyles } from '@mui/styles';
import {
	Container,
	TextField,
	Button,
	Typography,
	FormControl,
	FormLabel,
	RadioGroup,
	FormControlLabel,
	Radio,
	Checkbox,
	FormGroup,
	InputLabel,
	MenuItem,
	Select,
	InputAdornment,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';

import { contactQuestions } from '../../constants/contactQuestions';
import { validateEmail } from '../../utils/validation/contactForm';

// Actions
import { dispatchContactEmail } from '../../actions/index';

const useStyles = makeStyles({
	root: {
		'& .MuiDialogTitle-root, & .MuiDialogContent-root': {
			padding: '30px',
			paddingBottom: 0,
		},
		'& .MuiDialogActions-root': {
			padding: '30px',
			paddingTop: '10px',
		},
	},
	form: {
		display: 'flex',
		flexDirection: 'column',
		'& label, & div label': {
			fontSize: '1.3em',
		},
	},
	sideBySide: {
		display: 'flex',
		'& div': {
			flex: '1 1 auto',
			'&:first-child': {
				marginRight: '30px',
			},
		},
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	formItem: {
		marginBottom: '30px',
	},
});

const Contact = (props) => {
	const dispatch = useDispatch();
	const topOfForm = useRef();

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [age, setAge] = useState('');
	const [gender, setGender] = useState('');
	const [education, setEducation] = useState('');
	const [status, setStatus] = useState([]);
	const [goal, setGoal] = useState([]);
	const [currentSalary, setCurrentSalary] = useState('');
	const [desiredSalary, setDesiredSalary] = useState('');
	const [message, setMessage] = useState('');
	const [city, setCity] = useState('');
	const [reference, setReference] = useState('');
	const [industry, setIndustry] = useState('');
	const [state, setState] = useState('');
	const [country, setCountry] = useState('');
	const [errors, setErrors] = useState({});
	const [textUpdated, setTextUpdated] = useState(false);

	// useEffect(() => {
	//   console.log('started contact form')
	//   if (!textUpdated) {
	//     Mixpanel.track("Started Contact Form (App)")
	//     setTextUpdated(true)
	//   }
	// }, [firstName, lastName, email, age, message]);

	// used for both checkbox questions
	const handleCheckboxChange = (state, updateFunction, e) => {
		const option = e.target.name;
		if (state.includes(option)) {
			const newState = state.filter((item) => item !== option);
			updateFunction(newState);
		} else {
			const newState = [...state];
			newState.push(option);
			updateFunction(newState);
		}
	};

	const validateForm = () => {
		// copy errors
		const newErrors = {};

		if (firstName === '') {
			newErrors.firstName = 'First name is required';
		}
		if (lastName === '') {
			newErrors.lastName = 'Last name is required';
		}
		if (message === '') {
			newErrors.message = 'Message is required';
		}
		const emailStatus = validateEmail(email);
		if (!emailStatus.isValid) {
			newErrors.email = emailStatus.errors.email;
		}
		setErrors(newErrors);
		if (isEmpty(newErrors)) {
			return true;
		} else {
			// window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
			topOfForm.current.scrollIntoView({ behavior: 'smooth' });
			return false;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (validateForm()) {
			const formData = {
				firstName: firstName,
				lastName: lastName,
				email: email,
				age: age,
				message: message,
				gender: gender,
				status: status,
				goal: goal,
				education: education,
				industry: industry,
				currentSalary: currentSalary,
				desiredSalary: desiredSalary,
				reference: reference,
				city: city,
				state: state,
				country: country,
			};
			dispatch(dispatchContactEmail(formData));
			Mixpanel.track('Submitted Contact Form (App)');
			props.setOpen(false);
		}
	};

	const classes = useStyles();

	return (
		<Dialog
			open={props.open}
			maxWidth='md'
			className={classes.root}
			onClose={() => props.setOpen(false)}
		>
			<DialogTitle disableTypography>
				<Typography variant='h2' gutterBottom>
					Let's Chat!
				</Typography>
				<Typography gutterBottom>
					Fill out the form below for us to evaluate your needs. We will get
					back to you within 24 hours.
				</Typography>
			</DialogTitle>

			<DialogContent>
				<form
					id='contactForm'
					className={classes.form}
					noValidate
					autoComplete='off'
					onSubmit={handleSubmit}
				>
					<div className={classes.sideBySide}>
						<TextField
							id='firstName'
							label='First Name'
							ref={topOfForm}
							className={classes.formItem}
							onChange={(e) => setFirstName(e.target.value)}
							value={firstName}
							error={errors.firstName ? true : false}
							helperText={errors.firstName}
							type='text'
							required
						/>

						<TextField
							id='lastName'
							label='Last Name'
							className={classes.formItem}
							onChange={(e) => setLastName(e.target.value)}
							value={lastName}
							error={errors.lastName ? true : false}
							helperText={errors.lastName}
							type='text'
							required
						/>
					</div>

					<div className={classes.sideBySide}>
						<TextField
							id='email'
							label='Email'
							className={classes.formItem}
							onChange={(e) => setEmail(e.target.value)}
							value={email}
							error={errors.email ? true : false}
							helperText={errors.email}
							type='email'
							required
						/>

						<TextField
							id='age'
							label='Age'
							className={classes.formItem}
							onChange={(e) => setAge(e.target.value)}
							value={age}
							// error={errors.email ? true : false}
							// helperText={errors.email}
							type='number'
						/>
					</div>

					{/* Message */}
					<TextField
						id='message'
						label='Message'
						fullWidth
						className={classes.formItem}
						onChange={(e) => setMessage(e.target.value)}
						value={message}
						error={errors.message ? true : false}
						helperText={errors.message}
						type='text'
						multiline
						minRows={6}
						maxRows={10}
						required
						variant='outlined'
					/>

					<FormControl className={classes.formItem}>
						<FormLabel>Gender</FormLabel>
						<RadioGroup
							name='Gender'
							value={gender}
							className={classes.row}
							onChange={(e) => setGender(e.target.value)}
						>
							<FormControlLabel
								value='Female'
								control={<Radio />}
								label='Female'
							/>
							<FormControlLabel value='Male' control={<Radio />} label='Male' />
							<FormControlLabel
								value='Non-binary / GNC'
								control={<Radio />}
								label='Non-binary / GNC'
							/>
							<FormControlLabel
								value='Other'
								control={<Radio />}
								label='Other'
							/>
						</RadioGroup>
					</FormControl>

					<FormControl className={classes.formItem}>
						<FormLabel>{contactQuestions.status.name}</FormLabel>
						<FormGroup className={classes.row}>
							{contactQuestions.status.options.map((option, i) => (
								<FormControlLabel
									label={option}
									key={i}
									control={
										<Checkbox
											name={option}
											checked={eval(contactQuestions.status.id).includes(
												option
											)}
											onChange={(e) =>
												handleCheckboxChange(status, setStatus, e)
											}
										/>
									}
								/>
							))}
						</FormGroup>
					</FormControl>

					<FormControl className={classes.formItem}>
						<FormLabel>{contactQuestions.goal.name}</FormLabel>
						<FormGroup>
							{contactQuestions.goal.options.map((option, i) => (
								<FormControlLabel
									label={option}
									key={i}
									control={
										<Checkbox
											name={option}
											checked={eval(contactQuestions.goal.id).includes(option)}
											onChange={(e) => handleCheckboxChange(goal, setGoal, e)}
										/>
									}
								/>
							))}
						</FormGroup>
					</FormControl>

					<FormControl className={classes.formItem}>
						<FormLabel>Highest Education Level</FormLabel>
						<RadioGroup
							name='Gender'
							value={education}
							onChange={(e) => setEducation(e.target.value)}
						>
							<FormControlLabel
								value='High School Diploma'
								control={<Radio />}
								label='High School Diploma'
							/>
							<FormControlLabel
								value='Associate-Level'
								control={<Radio />}
								label='Associate-Level'
							/>
							<FormControlLabel
								value='Bachelor-Level'
								control={<Radio />}
								label='Bachelor-Level'
							/>
							<FormControlLabel
								value='Master-Level'
								control={<Radio />}
								label='Master-Level'
							/>
							<FormControlLabel
								value='Doctoral-Level or Juris Doctor'
								control={<Radio />}
								label='Doctoral-Level or Juris Doctor'
							/>
						</RadioGroup>
					</FormControl>

					{/* Industry */}
					<FormControl className={classes.formItem}>
						<InputLabel>{contactQuestions.industry.question}</InputLabel>
						<Select
							value={industry}
							onChange={(e) => setIndustry(e.target.value)}
						>
							{contactQuestions.industry.options.map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					<div className={classes.sideBySide}>
						<TextField
							id='currentSalary'
							label='Current Salary (pre-tax)'
							className={classes.formItem}
							onChange={(e) => setCurrentSalary(e.target.value)}
							value={currentSalary}
							type='number'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>$</InputAdornment>
								),
							}}
						/>

						<TextField
							id='desiredSalary'
							label='Desired Salary (pre-tax)'
							className={classes.formItem}
							onChange={(e) => setDesiredSalary(e.target.value)}
							value={desiredSalary}
							type='number'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>$</InputAdornment>
								),
							}}
						/>
					</div>

					{/* Reference */}
					<FormControl className={classes.formItem}>
						<InputLabel>{contactQuestions.reference.question}</InputLabel>
						<Select
							value={reference}
							onChange={(e) => setReference(e.target.value)}
							required
						>
							{contactQuestions.reference.options.map((option) => (
								<MenuItem key={option} value={option}>
									{option}
								</MenuItem>
							))}
						</Select>
					</FormControl>

					{/* CITY */}
					<TextField
						id='city'
						label='City'
						fullWidth
						className={classes.formItem}
						onChange={(e) => setCity(e.target.value)}
						value={city}
						type='text'
					/>

					<div className={classes.sideBySide}>
						{/* STATE */}
						<FormControl className={classes.formItem}>
							<InputLabel>{contactQuestions.state.question}</InputLabel>
							<Select value={state} onChange={(e) => setState(e.target.value)}>
								{contactQuestions.state.options.map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						{/* COUNTRY */}
						<FormControl className={classes.formItem}>
							<InputLabel>{contactQuestions.country.question}</InputLabel>
							<Select
								value={country}
								onChange={(e) => setCountry(e.target.value)}
							>
								{contactQuestions.country.options.map((option) => (
									<MenuItem key={option} value={option}>
										{option}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				</form>
			</DialogContent>

			<DialogActions>
				<Button onClick={() => props.setOpen(false)} color='primary'>
					Cancel
				</Button>
				<Button
					variant='contained'
					color='primary'
					autoFocus
					type='submit'
					form='contactForm'
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default withRouter(Contact);
