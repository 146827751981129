import React, { useState } from "react";
import { connect } from "react-redux";
import "../../styles/getnumberpopup.css";
import { makeStyles } from "@mui/styles";
import { Button, CircularProgress } from "@mui/material";
// Actions
import { fetchUserData } from "../../actions/index";
import { verifyOtp } from "../../actions";

const useStyles = makeStyles({
  buttonBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "30px",
  },
  hugeButton: {
    fontSize: "1.1em",
  },
});

const GetVerifyOtpPopup = (props) => {
  const { setOpenOtpPopup, userId } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [otp, setOtp] = useState("");

  const numberHandler = (event) => {
    setOtp(event.target.value.trim());
    // setOtp(event.target.value);
  };
  function isNumericStringValid(numberString) {
    // Check if the string contains only numeric digits
    if (
      // /^\d+$/.test(numberString) &&
      /^\d{6}$/.test(numberString)
    ) {
      return true;
    }
    setErrorMessage("The OTP should consist of 6 digits");
    return false;
  }
  const numberSubmitHandler = () => {
    if (isNumericStringValid(otp)) {
      setErrorMessage('')
      setLoading(true);
      verifyOtp(otp, userId)
        .then((res) => {
          // console.log(res);
          if (res.message === "otp matched") {
            // props.fetchUserData();
            // fetchUserData()

            const newTab = window.open("", "_blank");
            newTab.location.href = `/myer-briggs/?userId=${props.userId}`;
            window.location.reload();

            console.log("matched");
            setOpenOtpPopup(false);
            setErrorMessage("");
          } else if (res.message === "Request failed with status code 402") {
            setErrorMessage("OTP is Invalid");
          } else if (res.message === "Request failed with status code 403") {
            setErrorMessage("OTP expires");
          }
        })
        .catch((err) => {
          // console.log(err);
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <div className="getNumber-container">
      <div
        className="getNumber-bg-close"
        onClick={() => setOpenOtpPopup(false)}
      ></div>
      <div className="getNumber-data">
        <div className="getNumber-head">Enter OTP</div>
        <div style={{ paddingTop: "6px" }}>
          Enter 6 digits OTP sent to your phone number. OTP expires in 5 mins
        </div>
        <input
          type="number"
          placeholder="OTP"
          className="getNumber-input hidden-number-input"
          value={otp}
          onChange={numberHandler}
        />
        {errorMessage && (
          <div style={{ color: "red", fontSize: "14px", paddingTop: "8px" }}>
            {errorMessage}
          </div>
        )}
        <div className={classes.buttonBox}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            className={classes.hugeButton}
            disabled={loading}
            onClick={numberSubmitHandler}
          >
            {loading ? <CircularProgress /> : "confirm"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GetVerifyOtpPopup;
