import React from 'react';
import { makeStyles } from "@mui/styles";
import { motion } from "framer-motion"

// MUI Imports
import {} from '@mui/material';

const useStyles = makeStyles({
  root: {},
  bkg: {
    fill: 'rgba(0, 0, 0, 0.0)'
  },
  dividerLine: {
    strokeWidth: '0.3',
    stroke: 'rgb(128, 128, 128, 0.6)',
    // strokeDasharray: '1'
  },
  centerLine: {
    // stroke: 'rgb(128, 128, 128, 1)',
    strokeDasharray: "1"
  },
  scoreLine: {
    strokeWidth: '1.5',
    stroke: 'rgba(0, 0, 0, 0.2)',
    transition: 'all .5s ease'
  },
  bkgCurve: {
    stroke: 'rgb(128, 128, 128, 0.5)',
    strokeWidth: '0.1',
    fill: 'rgba(0, 0, 0, 0.05)'
  },
  highlightCurve: {
    stroke: 'rgb(35, 207, 0)',
    strokeWidth: '1.5',
    fill: 'rgba(20, 254, 0, 0.5)',
  },
  triangle: {
    fill: 'rgb(121, 121, 121)'
  }
});

const SD = (props) => {
  const { tScore, id } = props

  let position
  if (tScore < 20) {
    position = 0
  } else if (tScore < 40) {
    position = 20
  } else if (tScore < 60) {
    position = 40
  } else if (tScore < 80) {
    position = 60
  } else {
    position = 80
  }

  const classes = useStyles();

  return(
    <svg viewBox="0 0 100 20" xmlns="http://www.w3.org/2000/svg" className={classes.root}>
      <defs>
        <clipPath id={`clipRect-${id}`}>
          {/* Clips Selected Region */}
          <motion.rect width="20" height="20"
            animate={{x: position }}
            initial={{x: 0 }}
          />
        </clipPath>
        <clipPath id="clipCurve">
          {/* Clips Score Line */}
          <path d="M 0 20 C 40 20 40 1 50 1 C 60 1 60 20 100 20"></path>
        </clipPath>
      </defs>

      {/* BKG Rect */}
      <rect width="100" height="20" className={classes.bkg} />

      {/* Divider Lines */}
      <line x1="50" y1="0" x2="50" y2="20"
        clipPath="url(#clipCurve)"
        className={`${classes.dividerLine} ${classes.centerLine}`}
      />
      <line x1="60" y1="0" x2="60" y2="20"
        clipPath="url(#clipCurve)"
        className={classes.dividerLine}
      />
      <line x1="40" y1="0" x2="40" y2="20"
        clipPath="url(#clipCurve)"
        className={classes.dividerLine}
      />
      <line x1="20" y1="0" x2="20" y2="20"
        clipPath="url(#clipCurve)"
        className={classes.dividerLine}
      />
      <line x1="80" y1="0" x2="80" y2="20"
        clipPath="url(#clipCurve)"
        className={classes.dividerLine}
      />

      {/* Score Indicator */}
      {/* <motion.line
        y2="20"
        clipPath="url(#clipCurve)" className={classes.scoreLine}
        animate={{x1: tScore, x2: tScore}}
        initial={{x1: 0, x2: 0}}
      /> */}

      {/* Background Curve */}
      <path
        d="M 0 20 C 40 20 40 1 50 1 C 60 1 60 20 100 20"
        className={classes.bkgCurve}>
      </path>

      {/* Selection Curve */}
      <path
        d="M 0 20 C 40 20 40 1 50 1 C 60 1 60 20 100 20"
        clipPath={`url(#clipRect-${id})`}
        className={classes.highlightCurve}>
      </path>

      <motion.polygon
        points="0,20 2.5,17 5,20"
        className={classes.triangle}
        initial={{ translateX: '0px'}}
        animate={{ translateX: `${tScore-2.5}px`}}
      />

    </svg>
  )
}

export default SD;
