import React, { useEffect, useState } from 'react';
import { makeStyles } from "@mui/styles";
import { withRouter } from "react-router-dom";
import { Mixpanel } from "../../utils/mixpanel";
import { useQuery } from '@apollo/client';
// import { debounce } from '../../utils/helpers';

// Component Imports
import Hero from './Hero';
import HowItWorks from './HowItWorks';
import Testimonials from './Testimonials';
import OurPlans from './OurPlans';
import Grid from './Grid';
import Faq from './Faq';
import Contact from './Contact';

import { globalStyles } from './constants/globalStyles';

// GQL Query Imports
import { GET_LANDING_PAGE_DATA } from './constants/queries';

// MUI Imports
import {
  Container, Typography
} from '@mui/material';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const useGlobalStyles = makeStyles({
  ...globalStyles
});

const LandingPage = (props) => {
  const classes = useStyles();

  const [openContactForm, setOpenContactForm] = useState(false);

  // Load Contentful Data
  const { loading, error, data } = useQuery(GET_LANDING_PAGE_DATA);
  // console.log('loading:', loading, 'data:', data)

  if (error) {
    console.log('ERROR:', error)
  }

  let faqData = false
  let testimonialData = false
  let howItWorksData = false
  let gridData = false

  const findSection = (sectionId) => (
    data.module.contentCollection.items.find(section => section.id === sectionId)
  )

  if (!loading && data) {
    faqData = findSection('faqs')
    testimonialData = findSection('testimonials')
    howItWorksData = findSection('howItWorks')
    gridData = findSection('features')
  }

  useEffect(() => {
    // log mixpanel page view event
    Mixpanel.track("Viewed Landing Page");
  }, []);


  // // detect user scroll event
  // useEffect(() => {
  //   document.addEventListener("scroll", handleScroll)
  //   return () => {
  //     document.removeEventListener("scroll", handleScroll);
  //   }
  // }, []);

  // useEffect(() => {
  //   console.log('scrollEventSent:', scrollEventSent)
  // }, [scrollEventSent]);

  // const handleScroll = () => {
  //   const pixelsToTrigger = 700
  //   const scrollDist = window.scrollY
  //   setScrollY(window.scrollY)
  //   console.log('scrolled:', scrollY)
  //   if (scrollDist > pixelsToTrigger) {
  //     console.log('SCORLL EVENT!')
  //     // mixpanel.track("Scrolled To Plans");
  //   }
  // }

  const handleOpenContactForm = () => {
    Mixpanel.track("Opened Contact Form (App)")
    setOpenContactForm(true)
  }

  const PlanFeatures = () => {
    const globalClasses = useGlobalStyles();
    return (
      <div className={globalClasses.section} style={{
        backgroundColor: 'rgb(255, 255, 255)'
      }}>
        <Container maxWidth='md'>
          <Typography variant='h3' align='center' gutterBottom>
            {/* Choose the plan that’s right for you */}
            {gridData.sectionTitle}
          </Typography>

          <Grid
            showActionButtons={true}
            actionButtonText={'Get Started'}
            history={props.history}
            handleOpenContactForm={handleOpenContactForm}
            showContact={true}
          />

        </Container>
      </div>

    )
  }

  return(
    <div className={classes.root}>
      <Hero history={props.history} />

      { howItWorksData && <HowItWorks data={howItWorksData}/> }

      { testimonialData && <Testimonials data={testimonialData} /> }

      <OurPlans history={props.history} handleOpenContactForm={handleOpenContactForm}/>
{
      // <PlanFeatures />
}
      { faqData && <Faq data={faqData}/> }
      <Contact open={openContactForm} setOpen={setOpenContactForm} />
    </div>
  )
}

// export default withRouter(LandingPage);
export default withRouter(LandingPage);
