import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// MUI Imports
import {
  Container,
  Typography,
  Button,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Paper,
} from "@mui/material";

// Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";

// My Components
import Centered from "../layout/Centered";
import AssessmentProgressBar from "../widgits/AssessmentProgressBar";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { styled } from "@mui/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
// My Components
// Actions
import {
  updateAnswer,
  submitAnswers,
  fetchAnswers,
  sendFlash,
} from "../../actions/index";

const Depression = (props) => {
  const useStyles = makeStyles({
    root: {
      padding: "20px",
    },
    heading: {
      marginBottom: "20px",
      textAlign: "center"
    },
    buttonBox: {
      display: "flex",
      justifyContent: "center",
    },
    button: {
      margin: "10px",
    },
  });

  const classes = useStyles();

  const { assessment } = props;
  const questions = assessment.questions;
  const assessmentId = assessment._id;
  const userId = props.user._id;

  // console.log(props)

  useEffect(() => {
    if (!props.answersLoading) {
      // on load fetch completed answers
      let answerData = {
        userId: props.user._id,
        assessmentId: props.assessment._id,
      };
      props.fetchAnswers(answerData);
    }
  }, []);

  const handleSubmitAnswers = (e) => {
    // e.preventDefault()
    let answerData = {
      userId: userId,
      assessmentId: assessmentId,
      totalQuestions: props.assessment.totalQuestions,
    };
    props.submitAnswers(answerData);
  };

  // const calculateProgress = () => {
  //   if (assessment.questionsAnswered) {
  //     return (assessment.questionsAnswered / assessment.totalQuestions * 100).toFixed(1)
  //   } else return 0
  // }

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
  }));

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Container maxWidth="md" className={classes.root}>
      <AssessmentProgressBar assessmentId={assessmentId} />
      <Centered>
        <Typography variant="h3" className={classes.heading}>
        {props.assessment.name}
        </Typography>

        {props.answersLoading !== "done" && (
          <Centered>
            <CircularProgress />
          </Centered>
        )}

        <div className={classes.accordionContainer}>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              style={{ padding: "0px" }}
            >
              <Typography>
                <b>What is it?</b>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                A depression screening is standard in all of our career testing
                work. Our goal is to help people with undiagnosed depression to
                describe any feelings they have been having and to allow them
                the opportunity to seek support. We use the Beck Depression
                Inventory-II (BDI-II), a widely used inventory measuring
                depression in adolescents and adults. It’s not a diagnostic
                tool, but numerous studies provide evidence for its reliability
                and validity across racial groups, countries, and languages.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              style={{ padding: "0px" }}
            >
              <Typography>
                <b>Why is it used?</b>{" "}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                Our goal is to increase mental health awareness and prevent
                suicide. Implementing standard screenings like this and raising
                awareness of everyone’s mental health status will hopefully help
                people from slipping through the cracks untreated. With
                awareness, you have the opportunity to get the attention and
                help you need earlier which can help you to better manage your
                mental health.
              </Typography>
            </AccordionDetails>
          </Accordion>
          <br></br>
          <Typography>
            <b>Instructions</b>{" "}
          </Typography>
          <Typography>
            This questionnaire consists of 21 groups of statements. Please read
            each group of statements carefully , and then pick out the one
            statement in each group that best describes the way that you have
            been feeling during the <b>past two weeks including today</b>. If
            several statements in the group seem to apply equally well, choose
            the highest number (or more elevated answer) for that group.
          </Typography>
          <br></br>
          <Typography>
            <b>Time Estimate:</b> 45-60 minutes{" "}
          </Typography>
          <br></br>
        </div>

        <form>
          {Object.keys(questions).map((number) => {
            let question = questions[number];
            return (
              <Question
                key={number}
                number={number}
                question={question}
                userId={userId}
                assessmentId={assessmentId}
              />
            );
          })}
        </form>

        <div className={classes.buttonBox}>
          <Button
            variant="contained"
            className={classes.button}
            component={Link}
            to="/"
            onClick={handleSubmitAnswers}
            startIcon={<ArrowBackIcon />}
          >
            Save & Exit
          </Button>

          {/* <Button variant="contained" component={Link} to="/" className={classes.button}
            color="primary" onClick={handleSubmitAnswers}
          >
            Save
          </Button> */}

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            component={Link}
            to="/"
            onClick={handleSubmitAnswers}
            startIcon={<DoneIcon />}
          >
            I'm Finished
          </Button>
        </div>
      </Centered>
    </Container>
  );
};

let Question = (props) => {
  const useStyles = makeStyles({
    paper: {
      padding: "20px",
      marginBottom: "20px",
      display: "flex",
    },
    number: {
      display: "flex",
      flex: "0 0 20%",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "20px",
      color: "rgb(125, 125, 125)",
    },
    question: {
      display: "block",
    },
  });

  const { userId, assessmentId } = props;
  const classes = useStyles();

  const handleRadioChange = (event) => {
    const questionNum = event.target.name;
    const answer = event.target.value;
    props.updateAnswer(userId, assessmentId, questionNum, answer);
  };

  let answer = props.answer || "";
  return (
    <Paper className={classes.paper}>
      <div className={classes.number}>
        <Typography variant="h3">{props.number}.</Typography>
      </div>
      <FormControl className={classes.question}>
        <RadioGroup
          name={props.number}
          value={answer}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="0"
            control={<Radio />}
            label={props.question[0]}
          />
          <FormControlLabel
            value="1"
            control={<Radio />}
            label={props.question[1]}
          />
          <FormControlLabel
            value="2"
            control={<Radio />}
            label={props.question[2]}
          />
          <FormControlLabel
            value="3"
            control={<Radio />}
            label={props.question[3]}
          />
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};

const mapState = (state, ownProps) => {
  const answerIndex = ownProps.number - 1;
  const answers = state.answers.byId["depression-screening"];
  return {
    answer: answers ? answers.answers[answerIndex] : "",
  };
};
Question = connect(mapState, { updateAnswer })(Question);

const mapDispatchToProps = {
  updateAnswer,
  submitAnswers,
  fetchAnswers,
  sendFlash,
};

const mapStateToProps = (state) => {
  const assessmentId = "depression-screening";
  const answers = state.answers.byId[assessmentId];
  return {
    user: state.user,
    assessment: state.assessments.byId[assessmentId],
    answersLoading: answers ? answers.loading : false,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Depression);
