import React from "react";
import { connect } from "react-redux";
import { makeStyles } from '@mui/styles';

// My Components
import Results from "./Results";

const useStyles = makeStyles({
  root: {}
});

const UserResults = (props) => {
  return(
    <Results { ...props }/>
  )
}

const mapStateToProps = state => {
  return ({
    user: state.user,
    assessments: state.assessments,
    results: state.results,
    careerValueAnswers: state.answers.byId['career-values']
  })
}

export default connect(mapStateToProps)(UserResults);
