import React from 'react';
import { makeStyles } from '@mui/styles';

import { capitalize } from '../../utils/helpers';

// MUI Imports
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	LinearProgress,
	Chip,
	TableSortLabel,
	Typography,
} from '@mui/material';

import { colorStyles } from '../../constants/colorStyles';
import HappyBox from '../layout/HappyBox';

// Actions
// import {} from '../../actions/index';

const useStyles = makeStyles({
	root: {
		maxHeight: '600px',
	},
	bar: {
		height: '10px',
	},
	visuallyHidden: {
		border: 0,
		clip: 'rect(0 0 0 0)',
		height: 1,
		margin: -1,
		overflow: 'hidden',
		padding: 0,
		position: 'absolute',
		top: 20,
		width: 1,
	},
	...colorStyles,
});

const descendingComparator = (a, b, orderBy) => {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
};

const getComparator = (order, orderBy) => {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
};

const stableSort = (array, comparator) => {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
};

const EnhancedTableHead = (props) => {
	const classes = useStyles();
	const { order, orderBy, onRequestSort } = props;

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	const headCells = [
		{
			id: 'name',
			numeric: false,
			disablePadding: false,
			label: 'Occupational Scale',
		},
		{
			id: 'theme',
			numeric: false,
			disablePadding: false,
			label: 'Primary Theme',
		},
		{ id: 'theme', numeric: false, disablePadding: false, label: 'Theme Code' },
		{
			id: 'value',
			numeric: true,
			disablePadding: false,
			label: 'Standard Score',
		},
		{
			id: 'value',
			numeric: true,
			disablePadding: false,
			label: 'Standard Score',
		},
	];

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell, index) => (
					<TableCell
						key={index}
						align={headCell.numeric ? 'right' : 'left'}
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<span className={classes.visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
};

const BisResults = (props) => {
	const classes = useStyles();

	// converts object to array
	const initResultsState = () => {
		const dataObject = props.results;
		const data = Object.keys(dataObject).map((key) => dataObject[key]);
		return stableSort(data, getComparator(order, orderBy));
	};

	const [order, setOrder] = React.useState('desc');
	const [orderBy, setOrderBy] = React.useState('value');
	const [filter, setFilter] = React.useState(null);
	const [results, setResults] = React.useState(initResultsState());

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		const newOrder = isAsc ? 'desc' : 'asc';
		const newOrderBy = property;
		setOrder(newOrder);
		setOrderBy(newOrderBy);
		setResults(stableSort(results, getComparator(newOrder, newOrderBy)));
	};

	const toggleFilter = (theme) => {
		if (filter) {
			setFilter(null);
		} else {
			setFilter(theme);
		}
	};

	const filterTheme = (array) => {
		if (filter) {
			return array.filter((obj) => obj.theme === filter);
		} else {
			return array;
		}
	};

	return (
		<HappyBox id='os'>
			<Typography variant='h5' gutterBottom className={classes.subHeading}>
				Occupational Scales (OS)
			</Typography>
			<TableContainer className={classes.root}>
				<Table className={classes.table} size='small' stickyHeader>
					<EnhancedTableHead
						order={order}
						orderBy={orderBy}
						onRequestSort={handleRequestSort}
					/>

					<TableBody>
						{filterTheme(results).map((row, index) => {
							return (
								<TableRow key={index} hover>
									<TableCell component='th' scope='style'>
										{row.name}
									</TableCell>
									<TableCell>
										<Chip
											label={capitalize(row.theme)}
											size='small'
											onClick={() => toggleFilter(row.theme)}
											className={classes[row.theme]}
										/>
									</TableCell>
									<TableCell>
										<Chip label={capitalize(row.themeCode)} size='small' />
									</TableCell>
									<TableCell>
										<LinearProgress
											variant='determinate'
											value={row.value}
											className={classes.bar}
										/>
									</TableCell>
									<TableCell align='right'>{row.value}</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</HappyBox>
	);
};

export default BisResults;
