import React from 'react';
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";
import isEmpty from "is-empty";

// MUI Imports
import { LinearProgress } from '@mui/material';


const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: '0px',
    width: '100%',
    left: '0px',
    height: '18px'
  },
});

const calculateProgress = (totalQuestions, answers) => {
  let answerCount = 0
  answers.forEach(answer => {
    if (answer && !isEmpty(answer)) {
      // handle multiText deleted answers (empty string)
      if (Array.isArray(answer)) {
        if (!answer.includes('')) {
          answerCount += 1
        }
      } else {
        answerCount += 1
      }
    }
  })
  const progress = Math.round((answerCount / totalQuestions * 100) * 100) / 100
  // console.log('answerCount', answerCount)
  // console.log('totalQuestions', totalQuestions)
  // console.log('math:', answerCount / totalQuestions * 100)
  // console.log('progress', progress)
  return progress
}

// A simple component for centering its contents both horizontally and vertically
const AssessmentProgressBar = (props) => {
  const classes = useStyles();
  const { answers, totalQuestions } = props

  return(
    <div className={classes.root}>
      <LinearProgress
        variant="determinate"
        className={classes.root}
        value={calculateProgress(totalQuestions, answers)}
      />
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const assessmentId = ownProps.assessmentId
  const totalQuestions = state.assessments.byId[assessmentId].totalQuestions
  let answers = []
  if (state.answers.byId[assessmentId]) {
    answers = state.answers.byId[assessmentId].answers
    if (!answers) {
      answers = []
    }
  }

  return {
    totalQuestions: totalQuestions,
    answers: answers
  };
};

export default connect(mapStateToProps)(AssessmentProgressBar);
