import React from 'react';
import { makeStyles } from "@mui/styles";
import { useQuery } from '@apollo/client';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import { motion } from "framer-motion"

// MUI Imports
import {
  Typography, Tooltip, Chip, Avatar, Paper, CircularProgress
} from '@mui/material';

// My Imports
import Centered from "../layout/Centered";
import CustomTooltip from "../layout/CustomTooltip";
import HappyBox from "../layout/HappyBox";
import MbtiLetterBoxes from "../widgits/MbtiLetterBoxes";

// GQL Query Imports
import { GET_MBTI_RESULTS } from './constants/queries';

const LetterBoxes = (props) => {
  const { results } = props

  const useStyles = makeStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      margin: '30px 0'
    },
    box: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '20px',
      backgroundColor: 'rgb(236, 236, 236)',
      borderRadius: '4px',
      margin: '5px',
      width: '100px',
      height: '100px',
      '& h4': {
        fontSize: '3em',
        // fontWeight: '500'
      }
    }
  })

  const classes = useStyles();

  return(
    <div className={classes.root}>
      <div className={classes.box}>
        <Typography variant='h4'>
          {results.EI.subtype}
        </Typography>
      </div>

      <div className={classes.box}>
        <Typography variant='h4'>
          {results.SN.subtype}
        </Typography>
      </div>

      <div className={classes.box}>
        <Typography variant='h4'>
          {results.TF.subtype}
        </Typography>
      </div>

      <div className={classes.box}>
        <Typography variant='h4'>
          {results.JP.subtype}
        </Typography>
      </div>
    </div>
  )
}


const MbtiResults = (props) => {
  // console.log('props', props)

  const useStyles = makeStyles({
    root: {
      padding: '20px',
      // marginTop: '20px',
      marginBottom: '50px'
    }
  });

  const classes = useStyles();
  const results = props.results.MBTI.type
  const wholeType = results.wholeType

  // Load Contentful Data
  const { loading, error, data } = useQuery(GET_MBTI_RESULTS, {
    variables: { name: wholeType }
  });
  // console.log('loading:', loading, 'data:', data)

  if (loading) {
    return(
      <Centered>
        <CircularProgress />
      </Centered>
    )
  } else {
    const assessment = data.mbtiResults.assessment
    const mbtiType = data.mbtiType.items[0]
    const interpretation = data.mbtiResults.interpretation.json
    const mbtiComponents = data.mbtiResults.components.items
    const slots = data.mbtiResults.slots.items

    return(
      <>
        <HappyBox className={classes.root}>
          <Typography variant='h4' gutterBottom>
            Myers Briggs Type Indicator
          </Typography>

          <Typography gutterBottom component='div'>
            { documentToReactComponents(assessment.longDescription.json) }
          </Typography>
        </HappyBox>

        <HappyBox>
          <Typography variant='h4'>
            Your MBTI Type
          </Typography>
          <MbtiLetterBoxes type={mbtiType.name} slots={slots} />
          <BarGraph results={results} mbtiComponents={mbtiComponents} />
        </HappyBox>

        <HappyBox>
          <Typography gutterBottom component='div'>
            { documentToReactComponents(interpretation) }
          </Typography>
        </HappyBox>

        <HappyBox>
          <Typography variant='h4' gutterBottom>
            {mbtiType.name} Analysis
          </Typography>

          <Typography variant='h6' gutterBottom>
            "{mbtiType.functionalTitle}"
          </Typography>

          <Typography gutterBottom component='div'>
            { documentToReactComponents(mbtiType.profile.json) }
          </Typography>

        </HappyBox>

        <HappyBox>
          <Typography variant='h4' gutterBottom>
            {mbtiType.name}'s Work Motivation
          </Typography>

          <Typography component='div' gutterBottom>
            { documentToReactComponents(mbtiType.motivation.description.json)}
          </Typography>
        </HappyBox>
      </>
    )
  }
}

const BarGraph = (props) => {
  const { results } = props

  const useStyles = makeStyles({
    root: {
      marginBottom: '20px',
      userSelect: 'none'
    },
    labelsContainer: {
      display: 'flex',
      '& > :nth-child(odd)': {
        flex: '0 0 140px',
      }
    },
    labels: {
      display: 'flex',
      flex: '1 0 auto',
      margin: '0 10px',
      '& > div': {
        flex: `0 0 ${1/6*100}%`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
        textAlign: 'center',
        fontSize: '0.8em'
      },
      '& > :nth-child(1), & > :nth-child(7)': {
        flex: `0 0 ${5/60*100}%`,
      },
      marginBottom: '5px'
    },
    numbers: {
      display: 'flex',
      flex: '1 0 auto',
      // backgroundColor: 'rgb(208, 255, 243)',
      // transform: 'translateX(-50%)',
      margin: '0 10px',
      marginTop: '-5px',
      '& > div': {
        flex: `0 0 ${1/12 * 100}%`,
        display: 'flex',
        fontSize: '0.8em',
        justifyContent: 'center',
        transform: 'translateX(-50%)',
      }
    }
  })

  const classes = useStyles();

  return(
    <div className={classes.root}>
      <div className={classes.labelsContainer}>
        <div></div>
        <div className={classes.labels}>
          <div>Very Clear</div>
          <div>Clear</div>
          <div>Moderate</div>
          <div>Slight</div>
          <div>Moderate</div>
          <div>Clear</div>
          <div>Very Clear</div>
        </div>
        <div></div>
      </div>

      <BarRow results={results.EI} type='EI' typeA='e' typeB='i' mbtiComponents={props.mbtiComponents} />
      <BarRow results={results.SN} type='SN' typeA='s' typeB='n' mbtiComponents={props.mbtiComponents} />
      <BarRow results={results.TF} type='TF' typeA='t' typeB='f' mbtiComponents={props.mbtiComponents} />
      <BarRow results={results.JP} type='JP' typeA='j' typeB='p' mbtiComponents={props.mbtiComponents} />

      <div className={classes.labelsContainer}>
        <div></div>
        <div className={classes.numbers}>
          <div>30</div>
          <div>25</div>
          <div>20</div>
          <div>15</div>
          <div>10</div>
          <div>5</div>
          <div>0</div>
          <div>5</div>
          <div>10</div>
          <div>15</div>
          <div>20</div>
          <div>25</div>
          <div>30</div>
        </div>
        <div></div>
      </div>
    </div>
  )
}

const BarRow = (props) => {
  const { results, type, typeA, typeB, header } = props
  const A = props.mbtiComponents.find(component => component.id === typeA)
  const B = props.mbtiComponents.find(component => component.id === typeB)
  const value = results.level
  let leftPercent = 0
  let rightPercent = 0

  if (value > 0) {
    rightPercent = value / 30 * 100
  } else {
    leftPercent = Math.abs(value) / 30 * 100
  }

  const gradient = {
    slight: 'rgb(251, 229, 208)',
    moderate: 'rgb(254, 213, 175)',
    clear: 'rgb(251, 194, 139)',
    veryClear: 'rgb(252, 172, 97)'
  }

  const useStyles = makeStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '10px',
      // backgroundColor: 'rgb(212, 255, 228)',
    },
    barContainer: {
      display: 'flex',
      flex: '1 0 auto',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      margin: '0 10px',
    },
    halfBarZone: {
      position: 'relative',
      display: 'flex',
      flex: '1 0 50%',
      padding: '8px 0',
      '&:first-child': {
        justifyContent: 'flex-end',
        borderRight: '1px solid black'
      }
    },
    bar: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1
    },
    bkg: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      display: 'flex',
      '& :nth-child(1)': {
        flex: `0 0 ${1/6*100}%`,
        backgroundColor: gradient.slight
      },
      '& :nth-child(2)': {
        flex: `0 0 ${1/3*100}%`,
        backgroundColor: gradient.moderate
      },
      '& :nth-child(3)': {
        flex: `0 0 ${1/3*100}%`,
        backgroundColor: gradient.clear
      },
      '& :nth-child(4)': {
        flex: `0 0 ${1/6*100}%`,
        backgroundColor: gradient.veryClear
      }
    },
    bkgLeft: {
      flexDirection: 'row-reverse'
    },
    leftBar: {
      // flex: `0 0 ${leftPercent}%`,
    },
    rightBar: {
      // flex: `0 0 ${rightPercent}%`,
    },
    type: {
      display: 'flex',
      flex: '0 1 140px',
      // padding: '0 10px',
    },
    left: {
      justifyContent: 'flex-end'
    },
    chip: {
      // backgroundColor: 'red',
      fontSize: '1.1em'
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.root}>
      {/* Text Left */}
      <div className={`${classes.type} ${classes.left}`}>
        <CustomTooltip title={
          <>
            <Typography gutterBottom variant='h6'>{A.name}</Typography>
            <p>
              {A.description}
            </p>
          </>
        }
          arrow placement='top'
        >
          <Chip
            label={A.name}
            color={value < 0 ? 'primary' : 'default'}
            className={classes.chip}
            avatar={<Avatar>{type.charAt(0)}</Avatar>}
          />
        </CustomTooltip>
      </div>

      {/* Bar */}
      <div className={classes.barContainer}>
        <div className={classes.halfBarZone}>
          <div className={`${classes.bkg} ${classes.bkgLeft}`}>
            <div/><div/><div/><div/>
          </div>
          <Tooltip title={Math.abs(value)}>
            <motion.div
              className={classes.bar}
              initial={{ flexBasis: '0%' }}
              animate={{ flexBasis: `${leftPercent}%`}}
            />
          </Tooltip>
        </div>
        <div className={classes.halfBarZone}>
          <div className={classes.bkg}>
            <div/><div/><div/><div/>
          </div>
          <Tooltip title={value}>
            <motion.div
              className={classes.bar}
              initial={{ flexBasis: '0%' }}
              animate={{ flexBasis: `${rightPercent}%`}}
            />
          </Tooltip>
        </div>
      </div>

      {/* Text Right */}
      <div className={classes.type}>
        <CustomTooltip title={
          <>
            <Typography gutterBottom variant='h5'>{B.name}</Typography>
            <p>
              {B.description}
            </p>
          </>
        }
          arrow placement='top'
        >
          <Chip
            label={B.name}
            color={value > 0 ? 'primary' : 'default'}
            className={classes.chip}
            avatar={<Avatar>{type.charAt(1)}</Avatar>}
          />
        </CustomTooltip>
      </div>
    </div>
  )
}

export default MbtiResults
