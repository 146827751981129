import React, { useEffect } from "react";
import { connect } from "react-redux";

// My Components

import Centered from "../layout/Centered";

// MUI Imports
import { makeStyles } from "@mui/styles";
import { Typography, Container, CircularProgress } from "@mui/material";

// Actions
import { fetchUserResults, fetchUserQuestions } from "../../actions/index";

const useStyles = makeStyles({
  root: {
    marginTop: "50px",
  },
  answersSection: {
    marginTop: "50px",
  },
  question: {
    marginBottom: "10px",
  },
  answer: {
    padding: "10px",
    paddingLeft: "25px",
  },
  noAnswer: {
    color: "red",
  },
});

const Results = (props) => {
  // const [ questionsLoading, setQuestionsLoading ] = useState(false)
  const classes = useStyles();
  const userId = props.match.params.id;

  let answerNumber = 0;

  const { user, interviewQuestions } = props;
  const findAssessmentData = (assessmentId) => {
    if (user && user.assessmentData) {
      return user.assessmentData.find(
        (assessment) => assessment._id === assessmentId
      );
    }
  };

  const interviewAnswers = findAssessmentData("interview");

  useEffect(() => {
    if (!user) {
      // fetch user's results
      props.fetchUserResults(userId);
    } else if (!interviewQuestions) {
      // fetch interview questions that match the version of the assessment the user took
      const version = user.assessmentData.find(
        (assessment) => assessment._id === "interview"
      ).version;
      props.fetchUserQuestions("interview", version);
    }
  }, [user]);

  const Answer = (props) => {
    const { question, answerData } = props;
    let answer;
    const questionType = question.type;
    if (answerData) {
      switch (questionType) {
        case "text":
          answer = answerData;
          return (
            <div>{typeof answer !== "object" && answer !== null && answer}</div>

          );

        case "radio":
          const selection = answerData.selection;
          if (selection !== "other") {
            answer = question.choices[selection];
          } else {
            answer = `Other: ${answerData.other}`;
          }
          return <div>{answer}</div>;

        case "checkbox":
          return (
            <div>
              {Object.keys(answerData).map((choice) => {
                if (choice !== "other") {
                  answer = question.choices[choice];
                } else {
                  answer = `Other: ${answerData.other}`;
                }
                return <div key={choice}>{answer}</div>;
              })}
            </div>
          );

        case "multiText":
          return (
            <div>
              {question.textFields.map((textField, index) => {
                return (
                  <div key={index}>
                    {textField.label}: {answerData[index]}
                  </div>
                );
              })}
            </div>
          );

        default:
          return <div>Default Type: {questionType}</div>;
      }
    } else {
      return <div className={classes.noAnswer}>No Answer</div>;
    }
  };

  if (user && interviewAnswers && interviewQuestions) {
    return (
      <Container maxWidth="md" className={classes.root}>
        <Typography variant="h4" gutterBottom>
          Psychosocial Interview Responses
        </Typography>
        <Typography variant="h5" gutterBottom>
          Patient: {user.firstName} {user.lastName}
        </Typography>

        <div className={classes.answersSection}>
          {/* making these changes because new questions were added
           on 1st october, 2023. questions were added at position 
           11,12 and 48. many people gave the interview test before
            that and now database had change, so now I'm checking if 
            provided test is before 1st october or after that. if 
            date is before 1st Oct, 2023 then consider these logic */}
          {Object.keys(interviewQuestions).map((number) => {
            const question = interviewQuestions[number];

            // let answer = interviewAnswers.answers[`${number - 1}`]; //previous
            let answer = interviewAnswers.answers[`${answerNumber}`]; //after more questions

            const interviewCompletedDate = interviewAnswers.completedAt.slice(
              0,
              10
            ); //getting interview time date
            
            const selectedDate = new Date("2023-10-1").toJSON().slice(0, 10); //YY-MM-DD //getting date when questions were added

            if (interviewCompletedDate < selectedDate) {
              if (number - 1 === 10 || number - 1 === 11 || number - 1 === 47) {
                answer = "";
              } else {
                answerNumber++;
              }
            } else {
              answerNumber++;
            }
            return (
              <div key={number} className={classes.question}>
                {/* Question Text */}
                <Typography variant="h5">
                  {number}. {question.question}
                </Typography>

                {/* Answer */}
                <div className={classes.answer}>
                  <Answer question={question} answerData={answer} />
                </div>
              </div>
            );
          })}
        </div>
      </Container>
    );
  } else {
    return (
      <Centered>
        <CircularProgress />
      </Centered>
    );
  }
};

const mapDispatchToProps = {
  fetchUserResults,
  fetchUserQuestions,
};

const mapStateToProps = (state, ownProps) => {
  const userId = ownProps.match.params.id; // get user id
  const user = state.admin?.userDetail?.[userId] || false;
  let version = false;
  if (user) {
    version = user.assessmentData.find(
      (assessment) => assessment._id === "interview"
    ).version;
  }

  return {
    user: user,
    interviewQuestions:
      state.admin.interviewQuestionVersions?.[version - 1]?.questions
        ?.questions || false,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Results);
