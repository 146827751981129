import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { connect } from "react-redux";

// MUI Imports
import { Button, Paper, Typography, Container } from '@mui/material';

// My Imports
import CardSort from "./CardSort";

// Icons
import DoneIcon from '@mui/icons-material/Done';

// Actions
import {
  submitAnswers
} from '../../../actions/index';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0 auto',
    padding: '20px 0',
    maxWidth: '600px',
    margin: '0 auto'
  },
  instructions: {
    margin: '10px 5px',
    marginBottom: '20px',
    padding: '20px'
  },
  footer: {
    padding: '0 5px'
  },
  buttonBox: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '-12px'
  },
  button: {
    margin: '10px'
  }
});

const CareerValues = (props) => {
  const classes = useStyles();

  const handleSubmitAnswers = () => {
    let answerData = {
      userId: props.user._id,
      assessmentId: 'career-values',
      totalQuestions: props.assessment.totalQuestions,
      markComplete: true
    }
    props.submitAnswers(answerData, true)
    props.history.push('/')
  }

  return(
    <div className={classes.root}>

      {/* BODY */}
      <Container maxWidth='sm'>
        <Paper className={classes.instructions}>
          <Typography variant='h4' gutterBottom>
            Instructions
          </Typography>
          <Typography>
            Now please rank your top 8 career values in order of importance to you.
          </Typography>
        </Paper>
      </Container>
      <CardSort bins={['always']} rankMode={true} />
      {/* Footer */}
      <div className={classes.footer}>

        <div className={classes.buttonBox}>
          <Button
            variant="contained" color="primary" className={classes.button}
            onClick={handleSubmitAnswers}
            startIcon={<DoneIcon />}
          >
            I'm Finished
          </Button>
        </div>
      </div>

    </div>
  )
}

const mapDispatchToProps = {
  submitAnswers
};

const mapStateToProps = state => {
  return {
    user: state.user,
    assessment: state.assessments.byId['career-values'],
    answers: state.answers.byId['career-values']
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CareerValues);
