import React from 'react';
import { makeStyles } from "@mui/styles";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

// MUI Imports
import {
  Container, Typography, Accordion, AccordionSummary, AccordionDetails, Button
} from '@mui/material';

// Data Import
// import { faqs } from '../../constants/faq';

// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const useStyles = makeStyles({
  root: {
    padding: '0',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 240px',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  acordionsDiv: {
    width: '720px',
    margin: 'auto',
    '@media (max-width: 780px)' : {
      width: '100%',
    }
  },
  heading: {
    fontSize: '1.3em',
  },
  help: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '80px',
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
});

const Faq = (props) => {
  const classes = useStyles();

  const { data } = props
  const faqs = data.contentCollection.items

  return(
    <div className={`${classes.root}`}>
      <Container className={classes.container}>
        <Typography variant='h4' sx={{fontWeight: 800, marginBottom: '20px'}} align='center'>
          F. A. Q.
        </Typography>
        <Typography variant='h6' sx={{fontWeight: 800, marginBottom: '20px'}} align='center'>
          Let's Get it Clear
        </Typography>
        
        <div className={classes.acordionsDiv}>
          {faqs.map((faq, index) => (
            <Accordion key={index} sx={{margin: '20px auto', width: '100%'}}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{minHeight: '73px'}}>
                <Typography className={classes.heading}>
                  {faq.question}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography component='div'>
                  {documentToReactComponents(faq.answer.json)}
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </Container>
    </div>
  )
}

export default Faq;
