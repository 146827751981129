import React from 'react';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    // backgroundColor: 'teal',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  centered: {
    marginTop: '20px',
    padding: '20px',
    maxWidth: props => props.maxWidth || 'none'
  }
});

// A simple component for centering its contents both horizontally and vertically
const Centered = (props) => {
  const classes = useStyles(props);

  return(
    <div className={classes.root}>
      <div className={classes.centered}>
        {props.children}
      </div>
    </div>
  )
}

export default Centered;
